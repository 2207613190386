import { DeliveryDetails, TracingEventDetails } from "@dashboard-v3/api";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { PropsWithChildren, useState } from "react";
import request from "utils/apiRequestWithErrorCode";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import Title from "./Title";

export default function MailDelivered({
  event,
  recipient,
}: {
  event: TracingEventDetails;
  recipient: string;
}) {
  const { t } = useTranslation("tracing");
  const { enqueueSnackbar } = useSnackbar();
  const [details, setDetails] = useState<DeliveryDetails[]>();
  const [isLoading, setIsLoading] = useState(false);

  const code = parseCode(event);
  if (!code) return null;

  async function loadInfo() {
    const ts = event.createdAt;
    const params = new URLSearchParams();
    params.append("code", code);
    params.append("ts", ts.toString());
    params.append("recipient", recipient);
    const url = `/tracing/delivery-details?${params.toString()}`;
    setIsLoading(true);
    try {
      const response = await request<DeliveryDetails[]>("GET", url);
      setDetails(response);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("common:errors.fetchError"), {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  if (!details)
    return (
      <Layout
        event={event}
        showMore={<ShowMore onClick={loadInfo} isLoading={isLoading} />}
      />
    );

  if (details?.length === 0)
    return (
      <Layout event={event}>
        <Typography>{t("delivered.empty")}</Typography>
      </Layout>
    );

  if (details) {
    return (
      <Layout event={event}>
        {details.map((item, index) => (
          <Box ml={2} key={item.time}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              spacing={3}
            >
              <Grid item>
                <Property
                  title={t("delivered.at")}
                  value={new Date(item.time).toLocaleString()}
                />
              </Grid>
              <Grid item>
                <Property
                  title={t("delivered.delay")}
                  value={t("delivered.delaySeconds", { count: item.delay })}
                />
              </Grid>
              <Grid item>
                <Property title={t("delivered.relay")} value={item.relay} />
              </Grid>
              <Grid item xs={12}>
                <Property title={t("delivered.status")} value={item.status} />
              </Grid>
            </Grid>
            {index < details.length - 1 && (
              <Box my={2}>
                <Divider light />
              </Box>
            )}
          </Box>
        ))}
      </Layout>
    );
  }
}

function ShowMore({ onClick, isLoading }) {
  const { t } = useTranslation("tracing");

  function emitAction(event) {
    event.preventDefault();
    onClick();
  }

  return (
    <>
      <Link href="#" onClick={emitAction}>
        {t("delivered.showMore")}
      </Link>
      {isLoading && <CircularProgress size="1rem" color="inherit" />}
    </>
  );
}

type LayoutProps = {
  event: TracingEventDetails;
  showMore?: JSX.Element;
};

function Layout({ showMore, event, children }: PropsWithChildren<LayoutProps>) {
  const { t } = useTranslation("tracing");

  return (
    <>
      <Title timestamp={event.createdAt}>
        <Box display="flex" gridGap="1rem" alignItems="baseline">
          <Typography style={{ fontWeight: "500" }}>
            {t(`eventActionType.${event.action}`)}
          </Typography>

          {showMore}
        </Box>
      </Title>
      {children}
    </>
  );
}

function Property({ value, title }: { value: string; title: string }) {
  return (
    <Box display="flex" flexDirection="column">
      <Typography color="textSecondary" component="span" variant="caption">
        {title}
      </Typography>
      <Box>{value}</Box>
    </Box>
  );
}

function parseCode(event: TracingEventDetails) {
  const { serverResponse } = event.data;
  if (!serverResponse) return null;

  const parts = /queued as (.*)[\\n]?$/gm.exec(serverResponse as string);
  if (!parts || parts.length < 2) return null;
  return parts[1];
}
