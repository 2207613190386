import { Rule } from "pages/Rules/types";
import { getApprovalSteps } from "../CreateOrEdit/context/helpers";
import { useTranslation } from "react-i18next";

export default function ApprovalStepsDetails({ rule }: { rule: Rule }) {
  const { t } = useTranslation("rules");
  const { supervisors } = getApprovalSteps(rule);
  const typesWithCount = supervisors.reduce((acc, s) => {
    if (!s.type) return acc;
    if (acc[s.type]) {
      acc[s.type] = acc[s.type] + 1;
    } else {
      acc[s.type] = 1;
    }
    return acc;
  }, {});

  const types = Object.entries(typesWithCount).map(([key, value]) => {
    const count = value as number;
    const type = t(`approvalSteps.optionType.${key}`, { count });
    return `${value} ${type.toLowerCase()}`;
  });

  if (!types.length) return null;

  return (
    <>
      {t("approvalSteps.details.desc", {
        types: types.join(", ").replace(/, ([^,]*)$/, ` ${t("common:and")} $1`),
      })}
    </>
  );
}
