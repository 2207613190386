import { Box } from "@material-ui/core";
import { PropsWithChildren, useState } from "react";
import { Pagination } from "@material-ui/lab";
import random from "utils/random";
import { produce } from "immer";
import AddItemBtn from "./AddItemBtn";
import UploadFileBtn from "./UploadFileBtn";
import Search from "./Search";
import { Error, KeyValueItem, State } from "./types";
import ItemList from "./ItemList";

interface KeyValueProps {
  rows: Record<string, string[]>;
  onChange: (rows: Record<string, string[]>, isValid: boolean) => void;
}

const ITEMS_BY_PAGE = 10;

export default function KeyValueList({
  rows,
  onChange: emitChange,
}: KeyValueProps) {
  const [state, setState] = useState<State>(() => {
    const noRows = Object.keys(rows).length === 0;

    // New item
    if (noRows) {
      const emptyItem = { id: random(), key: "", value: "" };
      return {
        items: [emptyItem],
        errors: { [emptyItem.id]: { key: "isBlank", value: "isBlank" } },
        search: "",
        page: 1,
      };
    }

    // Edit item
    return {
      items: Object.keys(rows).map(
        key =>
          ({
            id: random(),
            key,
            value: rows[key][0],
          } as KeyValueItem)
      ),
      errors: {},
      search: "",
      page: 1,
    };
  });

  const downcaseSearch = (state.search || "").toLowerCase();
  const paginatedItems = chunks(
    filter(state.items, downcaseSearch),
    ITEMS_BY_PAGE
  );

  const showSearch = state.search || paginatedItems.length > 1;

  function updateSearch(search: string) {
    setState(
      produce(draft => {
        draft.search = search;
        draft.page = 1;
      })
    );
  }

  function addRow() {
    const newId = random();
    const updatedState = produce(state, draft => {
      draft.items.unshift({
        id: newId,
        key: "",
        value: "",
      });
      draft.search = "";
      draft.page = 1;
      draft.errors[newId] = { key: "isBlank", value: "isBlank" };
    });

    setState(updatedState);
    emitChange(itemsToRows(updatedState.items), false);
  }

  function setPage(newPage: number) {
    setState(
      produce(draft => {
        draft.page = newPage;
      })
    );
  }

  function isValid(errors: Error) {
    return Object.keys(errors).length === 0;
  }

  return (
    <>
      <Box display="flex" flexDirection="column">
        <ActionButtons>
          <AddItemBtn onClick={addRow} />

          <UploadFileBtn
            state={state}
            onChange={newState => {
              setState(newState);
              emitChange(itemsToRows(newState.items), isValid(newState.errors));
            }}
          />

          {showSearch && (
            <Search value={state.search} onChange={updateSearch} />
          )}
        </ActionButtons>
      </Box>

      <ItemList
        paginatedItems={paginatedItems}
        state={state}
        onChange={updatedState => {
          setState(updatedState);
          emitChange(
            itemsToRows(updatedState.items),
            isValid(updatedState.errors)
          );
        }}
      />

      {paginatedItems.length > 1 && (
        <Pagination
          count={paginatedItems.length}
          page={state.page}
          onChange={(_, newPage) => setPage(newPage)}
          size="small"
        />
      )}
    </>
  );
}

function ActionButtons({ children }: PropsWithChildren) {
  return (
    <Box
      display="flex"
      gridGap="15px"
      alignItems="flex-start"
      mb="20px"
      mt="10px"
    >
      {children}
    </Box>
  );
}

function itemsToRows(items: KeyValueItem[]): Record<string, string[]> {
  return items.reduce((acc, item) => {
    acc[item.key] = [item.value];
    return acc;
  }, {});
}

function chunks(items: KeyValueItem[], perChunk: number) {
  return items.reduce(
    (chunks, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);
      if (!chunks[chunkIndex]) {
        chunks[chunkIndex] = []; // start a new chunk
      }

      chunks[chunkIndex].push(item);

      return chunks;
    },
    [[]] as KeyValueItem[][]
  );
}

function filter(items: KeyValueItem[], filter: string) {
  if (!filter) return items;
  return items.filter(item => item.key.toLowerCase().startsWith(filter));
}
