import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Grid,
  ListItem,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { green, orange, red } from "@material-ui/core/colors";
import LensIcon from "@material-ui/icons/Lens";
import BugReportIcon from "@material-ui/icons/BugReport";
import { TextWithElipisis } from "./styled";
import { getFilename, getParsedDate, isExpired } from "../utils";
import { MxheroShare } from "@dashboard-v3/api";
import styled from "styled-components";

interface StorageShareItemProps {
  item: MxheroShare;
  loading: boolean;
  onRevoke: (id: string) => void;
}

export default function MxheroShareItem(props: StorageShareItemProps) {
  const { t } = useTranslation("storageShares");
  const history = useHistory();
  const { item, onRevoke, loading } = props;
  const isAvailable = item.expiresAt ? !isExpired(item.expiresAt) : true;
  const isMalware = Boolean(item.malware) && !item.malware.allowedToUse;

  const classes = useStyles();
  const statusText = t(getStatusText(isMalware, isAvailable));

  const onEdit = () => {
    history.push({
      pathname: `/mxhero-shares/${item.shareId}`,
      state: { selectedShare: item, shareId: item.shareId },
    });
  };

  const statusColor = isAvailable ? green.A400 : orange.A200;

  return (
    <ListItem disableGutters>
      <Accordion className={classes.accordion} expanded={false}>
        <AccordionSummary style={{ cursor: "default", padding: 0 }}>
          <Grid container alignItems="center">
            <Grid item>
              <Tooltip title={statusText}>
                <Box px={2}>
                  {isMalware ? (
                    <MalwareIcon htmlColor={red.A200} />
                  ) : (
                    <StatusIcon htmlColor={statusColor} />
                  )}
                </Box>
              </Tooltip>
            </Grid>
            <Grid item sm zeroMinWidth>
              <ContentDetails item={item} />
            </Grid>
            <Grid item>
              <Box pr={2} pl={3}>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => onRevoke(item.shareId)}
                  disabled={loading || isExpired(item.expiresAt)}
                >
                  {t("list.item.expireBtn")}
                </Button>
                <Button
                  className={classes.editBtn}
                  size="small"
                  variant="outlined"
                  color="default"
                  onClick={() => onEdit()}
                >
                  {t("common:edit")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
      </Accordion>
    </ListItem>
  );
}

function ContentDetails({ item }: { item: MxheroShare }) {
  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item zeroMinWidth>
        <ShareFileName item={item} />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item>
            <Owner item={item} />
          </Grid>
          <Grid item>
            <Donwloadable item={item} />
          </Grid>
          <Grid item>
            <Expiration item={item} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function getStatusText(isMalware: boolean, isAvailable: boolean) {
  if (isMalware) return "list.item.status.malware";
  if (isAvailable) return "list.item.status.enabled";
  return "list.item.status.expired";
}

function Owner({ item }: { item: MxheroShare }) {
  const { t } = useTranslation("storageShares");
  return (
    <>
      <SmallLabel>{t("list.item.owner")}</SmallLabel>
      <Tooltip title={item.owner}>
        <TextWithElipisis display="inline" variant="subtitle2">
          {item.owner}
        </TextWithElipisis>
      </Tooltip>
    </>
  );
}

function Donwloadable({ item }: { item: MxheroShare }) {
  const { t } = useTranslation("storageShares");
  return (
    <SmallLabel>
      {item.canDownload
        ? t("list.item.isDownloadable")
        : t("list.item.isNotDownloadable")}
    </SmallLabel>
  );
}

function Expiration({ item }: { item: MxheroShare }) {
  const { t } = useTranslation("storageShares");
  return (
    <SmallLabel>
      {!item.expiresAt
        ? t("list.item.noExpiration")
        : t("list.item.expiresAt", {
            date: getParsedDate(item.expiresAt),
          })}
    </SmallLabel>
  );
}

const SmallLabel = ({ children }) => {
  return (
    <Typography
      color="textSecondary"
      component="span"
      variant="subtitle2"
      style={{ fontSize: "13px", marginRight: "5px" }}
    >
      {children}
    </Typography>
  );
};

const ShareFileName = ({ item }: { item: MxheroShare }) => {
  return (
    <>
      <Typography variant="caption" color="textSecondary">
        {item.type === "FOLDER" ? "Folder" : "File"}{" "}
      </Typography>
      <Tooltip title={item.itemId}>
        <Box style={{ fontSize: ".95rem" }}>{getFilename(item)}</Box>
      </Tooltip>
    </>
  );
};

const useStyles = makeStyles<Theme, {}, "accordion" | "editBtn" | "overrides">({
  accordion: { width: "100%" },
  editBtn: { alignSelf: "center", marginLeft: "10px" },
  overrides: {
    MuiExpansionPanelSummary: {
      root: {
        "&:hover:not(.Mui-disabled)": {
          cursor: "default",
        },
      },
    },
  },
});

const StatusIcon = styled(LensIcon)`
  margin-left: 10px;
  font-size: 0.8rem;
  margin-right: 6px;
`;

const MalwareIcon = styled(BugReportIcon)`
  font-size: 1.2rem;
  margin-right: 4px;
  margin-left: 6px;
`;
