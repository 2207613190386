import { Box, MenuItem, Select } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CreateForm } from ".";

type Params = {
  value: CreateForm["repeatEvery"];
  onChange: (value: CreateForm["repeatEvery"]) => void;
};
export default function SelectRepeat({ value, onChange: emitChange }: Params) {
  const { t } = useTranslation("datasets");

  return (
    <Box display="flex" alignItems="center" mt={2}>
      <Box mr={2}>{t("syncForm.repeatLabel")}</Box>

      <Select
        variant="outlined"
        value={(value ?? 0).toString()}
        onChange={event => {
          const newValue = parseInt(
            event.target.value as string,
            10
          ) as CreateForm["repeatEvery"];

          emitChange(newValue);
        }}
      >
        <MenuItem value="0">{t("syncForm.noRepeat")}</MenuItem>
        <MenuItem value="6">{t("syncForm.every6h")}</MenuItem>
        <MenuItem value="12">{t("syncForm.every12h")}</MenuItem>
        <MenuItem value="24">{t("syncForm.every24h")}</MenuItem>
      </Select>
    </Box>
  );
}
