import { useTranslation } from "react-i18next";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { Button } from "components/Forms/StyledComponents";
import SectionHeader from "components/SectionHeader";
import FilterPolicyList from "./List";
import FilterPolicyForm from "./Form";

export default function FilterPolicies() {
  const { t } = useTranslation("filterPolicies");
  const history = useHistory();
  const { pathname } = useLocation();
  const isOnForm = pathname.includes("/edit") || pathname.includes("/new");
  const btnWording = isOnForm ? t("common:backToList") : t("common:create");

  const handleRouting = () => {
    return history.push(isOnForm ? "/filter-policies" : "/filter-policies/new");
  };

  return (
    <>
      <SectionHeader
        title={t("title")}
        sideBtn={<Button wording={btnWording} onClick={handleRouting} />}
      >
        <Typography>{t(["description", ""])}</Typography>
      </SectionHeader>
      <Switch>
        <Route path="/filter-policies" exact>
          <FilterPolicyList />
        </Route>
        <Route path="/filter-policies/new">
          <FilterPolicyForm />
        </Route>
        <Route path="/filter-policies/:id/edit" exact>
          <FilterPolicyForm />
        </Route>
      </Switch>
    </>
  );
}
