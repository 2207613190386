import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";

export default function AddItemBtn({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation("datasets");

  return (
    <Button
      color="primary"
      disableElevation
      size="small"
      variant="outlined"
      startIcon={<AddIcon />}
      onClick={onClick}
    >
      {t("form.buttons.addItem")}
    </Button>
  );
}
