import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { OptionTitle } from "components/Styled";
import { useTranslation } from "react-i18next";

export default function ReplaceOrMerge({
  value,
  onChange: emitChange,
}: {
  value: "REPLACE" | "MERGE";
  onChange: (newValue: "REPLACE" | "MERGE") => void;
}) {
  const { t } = useTranslation("datasets");

  return (
    <FormControl>
      <OptionTitle>{t("syncForm.actionTitle")}</OptionTitle>

      <RadioGroup
        aria-label="action"
        name="action"
        value={value}
        onChange={event => {
          emitChange(event.target.value as "REPLACE" | "MERGE");
        }}
      >
        <Box display="flex" mt=".5em" gridGap="20px">
          <FormControlLabel
            value="REPLACE"
            control={<Radio color="primary" />}
            label={t("syncForm.replace")}
          />
          <FormControlLabel
            value="MERGE"
            control={<Radio color="primary" />}
            label={t("syncForm.merge")}
          />
        </Box>
      </RadioGroup>
    </FormControl>
  );
}
