import { Box, IconButton, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";

export default function Search({
  onChange,
  value,
}: {
  onChange: (value: string) => void;
  value: string;
}) {
  const { t } = useTranslation("datasets");

  return (
    <Box ml="10px">
      <TextField
        size="small"
        placeholder={t("form.search.placeholder")}
        value={value}
        onChange={event => onChange(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="disabled" fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: Boolean(value) && (
            <IconButton size="small" onClick={() => onChange("")}>
              <ClearIcon color="disabled" fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </Box>
  );
}
