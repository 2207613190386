import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  ListSubheader,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SelectField from "components/Forms/SelectField";
import { OptionGroup } from "components/Styled";
import { StyledMenuItem } from "../styled";
import { useRuleContext } from "../../../context/ruleContext";
import { getApprovalSteps } from "../../../context/helpers";

import { Supervisor } from "@dashboard-v3/api";
import { ReviewerOption } from "../types";
import { Skeleton } from "@material-ui/lab";

interface ReviewerSelectProps {
  index: number;
  loading: boolean;
  options: ReviewerOption[];
  value: Supervisor;
}
export default function ReviewerSelect({
  index,
  value,
  loading,
  options,
}: ReviewerSelectProps) {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const { supervisors } = getApprovalSteps(state.rule);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const canRemoveReviewer = supervisors.length > 1;

  function getFilteredOptions() {
    return options.filter(option => {
      if (option.type === "SEARCH") return true;
      return option.label.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }

  const renderValue = selectedValue => {
    const option = options.find(opt => {
      return opt.value === selectedValue;
    });

    if (!option) return "";

    return option.label;
  };

  function removeReviewer() {
    return () => {
      const update = [...supervisors];
      update.splice(index, 1);
      dispatch({
        type: "updateStep",
        payload: { step: "approvalSteps", changes: { supervisors: update } },
      });
    };
  }

  function onChange(e) {
    const { value } = e.target;
    const [entity, type] = value.split(":");

    const update = supervisors.map((supervisor, i) => {
      if (i === index) return { entity, type };
      return supervisor;
    });

    dispatch({
      type: "updateStep",
      payload: { step: "approvalSteps", changes: { supervisors: update } },
    });
  }

  function getValue() {
    if (!value.entity || !value.type || !options.length) return "";
    return `${value.entity}:${value.type}`;
  }

  if (loading) {
    return (
      <OptionGroup>
        <Skeleton
          variant="rect"
          animation="wave"
          height={56}
          style={{ marginTop: 24 }}
        />
      </OptionGroup>
    );
  }

  return (
    <Grid container alignItems="center" spacing={3} style={{ marginTop: 12 }}>
      <Grid item xs={11}>
        <SelectField<ReviewerOption>
          label={t("approvalSteps.select.label")}
          disabled={loading || !options.length}
          options={getFilteredOptions()}
          value={getValue()}
          onClose={() => setSearchTerm("")}
          onChange={onChange}
          renderValue={renderValue}
          renderOption={option => {
            if (option.type === "SEARCH") {
              return (
                <ListSubheader disableSticky={true} key={option.key}>
                  <TextField
                    label={t(option.label)}
                    onClick={e => e.stopPropagation()}
                    onKeyDown={e => e.stopPropagation()}
                    onChange={e => setSearchTerm(e.target.value)}
                    value={searchTerm}
                    autoFocus
                    fullWidth
                    style={{ marginTop: "6px", marginBottom: "6px" }}
                    InputLabelProps={{ shrink: true }}
                  />
                </ListSubheader>
              );
            }
            return (
              <StyledMenuItem key={option.key} value={option.value}>
                <Typography variant="caption">
                  {t(`approvalSteps.optionType.${option.type}`)}
                </Typography>
                <Typography>{option.label}</Typography>
              </StyledMenuItem>
            );
          }}
        />
      </Grid>
      <Grid item xs={1}>
        {canRemoveReviewer && (
          <Tooltip title={t("approvalSteps.removeStepBtn")}>
            <IconButton size="small" onClick={removeReviewer()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
}
