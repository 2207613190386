import { useTranslation } from "react-i18next";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { EmailFlow } from "@dashboard-v3/api";
import { Rule } from "pages/Rules/types";

interface SMTPProps {
  ruleType: Rule["actionType"];
  value: boolean;
  onChange: (update: Partial<EmailFlow>) => void;
}

export default function SMTPSelector(props: SMTPProps) {
  const { t } = useTranslation("rules");
  const { ruleType, value, onChange } = props;
  const currentValue = value ? "ENABLED" : "DISABLED";

  function handleOnChange(_, value: string) {
    const isEnabled = value === "ENABLED";
    const update: Partial<EmailFlow> = { smtpPhase: isEnabled };

    if (isEnabled) {
      update.bidirectional = false;
    }

    onChange(update);
  }

  return (
    <RadioGroup
      style={{ marginBottom: 24 }}
      value={currentValue}
      onChange={handleOnChange}
    >
      <FormControlLabel
        value="DISABLED"
        control={<Radio color="primary" size="small" />}
        label={
          <Typography variant="caption" style={{ fontSize: 14 }}>
            {t(`targetStep.smtp.${ruleType}.disable`)}
          </Typography>
        }
      />
      <FormControlLabel
        value="ENABLED"
        control={<Radio color="primary" size="small" />}
        label={
          <Typography variant="caption" style={{ fontSize: 14 }}>
            {t(`targetStep.smtp.${ruleType}.enable`)}
          </Typography>
        }
      />
    </RadioGroup>
  );
}
