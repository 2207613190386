import { State } from "../types";
import produce from "immer";

export type UpdateFilterPolicy = {
  type: "updateFilterPolicy";
  payload: { filterPolicyId: string };
};

export function updateFilterPolicy(
  state: State,
  payload: UpdateFilterPolicy["payload"]
): State {
  return produce(state, draft => {
    if (payload.filterPolicyId) {
      draft.rule.filterPolicyId = payload.filterPolicyId;
      return;
    }

    delete draft.rule.filterPolicyId;
  });
}
