import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Typography, IconButton, Grid, Paper } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import WithIconHelper from "components/WithIconHelper";
import ActionFilters from "pages/Rules/ActionFilters";
import TargetFilters from "./Target";
import DeleteDialog from "./DeleteDialog";
import {
  actionFilter,
  archivingAddressFilter,
  emailFlowFilter,
  getFilterTranslations,
  mailboxFilter,
} from "./helpers";
import { useRuleContext } from "../context/ruleContext";
import FloatingButton from "../FloatingButton";

import { Rule } from "pages/Rules/types";
import { RuleUrlParams } from "..";

type Props = {
  type: "action" | "target";
};

export default function Filters({ type }: Props) {
  const { t } = useTranslation("rules");
  const { action }: RuleUrlParams = useParams();
  const { state, dispatch } = useRuleContext();
  const [filtersAdded, setFiltersAdded] = useState(
    isAlreadyAdded(type, state.rule)
  );
  const [expanded, setExpanded] = useState(filtersAdded ? false : true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const isEdit = Boolean(state.rule.id) && action === "edit";
  const isTargetFilter = type === "target";
  const isMailboxEdit = isEdit && state.rule.targetType === "MAIL_BOX";
  const filterText = getFilterTranslations(state, type);

  const addFilterDisabled =
    isTargetFilter && (isMailboxEdit || !state.rule?.targetType);

  const handleDeleteChoose = (chooseDelete: boolean) => {
    setOpenDeleteDialog(false);
    if (chooseDelete) {
      const actionType = isTargetFilter
        ? "updateFilterTarget"
        : "updateFilterAction";

      dispatch({ type: actionType, payload: null });
      setFiltersAdded(false);
    }
  };

  const handleExpanded = () => {
    setExpanded(prevState => !prevState);
  };

  if (!filtersAdded) {
    return (
      <FloatingButton
        label={t("addFilters")}
        onClick={() => setFiltersAdded(true)}
        disabled={addFilterDisabled}
        testid={`filters__add-${type}-filters-btn`}
      />
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <Paper>
          <StepHeader>
            <WithIconHelper
              position="after"
              text={t(filterText.tooltip)}
              buttonStyles={{ margin: "2px 0 0 4px" }}
              display={!isTargetFilter}
            >
              <Typography variant="h6">{t(filterText.title)}</Typography>
            </WithIconHelper>
            <div style={{ textAlign: "right" }}>
              <IconButton
                data-testid={`filters__delete-${type}-filter-btn`}
                disabled={isTargetFilter && isMailboxEdit}
                onClick={() => {
                  setOpenDeleteDialog(true);
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
              <DeleteDialog
                question={t("filters.doYouWantToDelete")}
                onChoose={handleDeleteChoose}
                open={openDeleteDialog}
              />
              <IconButton
                data-testid={`filters__expand-${type}-filter-btn`}
                onClick={handleExpanded}
                edge="end"
              >
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
          </StepHeader>
          {expanded && (
            <StepContent>
              {type === "action" && <ActionFilters />}
              {type === "target" && <TargetFilters />}
            </StepContent>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

const isAlreadyAdded = (type: Props["type"], rule: Rule) => {
  if (type === "action") {
    const filter = actionFilter(rule);
    return Boolean(filter);
  }
  if (type === "target") {
    if (Boolean(rule.filterPolicyId)) return true;

    if (rule.targetType === "ARCHIVING_ADDRESS") {
      const filter = archivingAddressFilter(rule);
      return Boolean(filter);
    }
    if (rule.targetType === "EMAIL_FLOW") {
      const filter = emailFlowFilter(rule);
      return Boolean(filter);
    }
    if (rule.targetType === "MAIL_BOX") {
      const filter = mailboxFilter(rule);
      return Boolean(filter);
    }
  }
  return false;
};

const StepHeader = styled.header`
  align-items: center;
  border-bottom: 1px solid #d6dbe0;
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: 1fr 1fr;
  padding: 5px 24px;
`;

const StepContent = styled.div`
  padding: 24px;
`;
