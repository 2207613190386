import { useReducer } from "react";
import TargetStep from "./Steps/Target";
import Filters from "./Filters";
import { Grid, Paper, TextField } from "@material-ui/core";
import styled from "styled-components";
import { initialState, reducer } from "./context";
import { RuleContext } from "./context/ruleContext";
import RuleActions from "../RuleActions";
import SaveButton from "./SaveButton";
import { Rule } from "../types";
import useSaveRule from "./useSaveRule";
import CloudStorage from "../CloudStorage";
import StorageFilepath from "../StorageFilepath";
import useAsyncValidations from "./useAsyncValidations";
import { useTranslation } from "react-i18next";
import VerticalStepper from "pages/Rules/RuleStepper/VerticalStepper";
import TargetWarningModal from "./TargetWarningModal";
import AddAction from "./AddAction";
import ApprovalSteps from "./Steps/ApprovalSteps";

const SidePaper = styled(Paper)`
  padding: 24px;
  position: sticky;
  top: 90px;
`;

export default function RuleForm({ rule }: { rule: Rule }) {
  const [state, dispatch] = useReducer(reducer, initialState(rule));
  const showStorageAndAction =
    state.type !== "BLOCK_ATTACHMENTS" && state.type !== "REQUEST_APPROVAL";

  useSaveRule(state, dispatch);
  useAsyncValidations(state, dispatch);

  return (
    <RuleContext.Provider value={{ state, dispatch }}>
      <Grid container spacing={5}>
        <Grid item xs={6} sm>
          {state.type !== "REQUEST_APPROVAL" && (
            <>
              <RuleActions />

              <WrapVerticalLine>
                <Filters type="action" />
              </WrapVerticalLine>
            </>
          )}

          <TargetStep />

          <WrapVerticalLine>
            <Filters type="target" />
          </WrapVerticalLine>

          {state.type === "REQUEST_APPROVAL" && (
            <>
              <ApprovalSteps />
              <WrapVerticalLine>
                <Filters type="action" />
              </WrapVerticalLine>
            </>
          )}

          {showStorageAndAction && (
            <>
              <CloudStorage />

              <VerticalLine />

              <StorageFilepath />

              <WrapVerticalLine>
                <AddAction />
              </WrapVerticalLine>
            </>
          )}

          <Grid container spacing={3}>
            <Grid item xs>
              <Paper style={{ padding: "25px 24px" }}>
                <SaveButton />
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <SidePaper>
            <NameInput
              value={state.rule?.name}
              onChange={ruleName =>
                dispatch({
                  type: "updateRuleLabels",
                  payload: { type: "name", input: ruleName },
                })
              }
            />

            <DescriptionInput
              value={state.rule?.description}
              onChange={description =>
                dispatch({
                  type: "updateRuleLabels",
                  payload: { type: "description", input: description },
                })
              }
            />
            <VerticalStepper
              rule={state.rule}
              currentStep={state.currentStep}
              stepsState={state.steps}
            />
          </SidePaper>
        </Grid>
      </Grid>
      <TargetWarningModal rule={rule} />
    </RuleContext.Provider>
  );
}

function WrapVerticalLine({ children }) {
  return (
    <>
      <VerticalLine style={{ height: "30px" }} />
      {children}
      <VerticalLine style={{ height: "30px" }} />
    </>
  );
}

const VerticalLine = styled.div`
  height: 100px;
  width: 50%;
  margin: 5px 0;
  border-right: 2px dashed #8f8f8f;
`;

const DescriptionInput = ({ value, onChange }) => {
  const { t } = useTranslation("rules");
  return (
    <TextField
      fullWidth
      label={t("ruleDescription")}
      margin="normal"
      multiline
      placeholder={t("typeADescripton")}
      minRows={4}
      variant="outlined"
      style={{ marginBottom: "24px" }}
      inputProps={{ "data-testid": "rules-steps_description-textarea" }}
      value={value ?? ""}
      onChange={event => {
        onChange(event.target.value);
      }}
    />
  );
};

const NameInput = ({ value, onChange }) => {
  const { t } = useTranslation("rules");

  return (
    <TextField
      fullWidth
      label={t("ruleName")}
      margin="normal"
      placeholder={t("typeAName")}
      type="text"
      variant="outlined"
      inputProps={{ "data-testid": "rules-steps_name-input" }}
      value={value ?? ""}
      onChange={event => {
        onChange(event.target.value);
      }}
    />
  );
};
