import { useTranslation } from "react-i18next";
import { Grid, Paper, Typography } from "@material-ui/core";
import { StepHeader } from "components/Styled";
import StepsList from "./StepsList";
import ConfirmButton from "./ConfirmButton";
import AdvancedOptions from "./AdvancedOptions";
import { useRuleContext } from "../../context/ruleContext";

import { State } from "pages/Rules/CreateOrEdit/context/types";

export default function ApprovalSteps() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const expanded = state.steps.approvalSteps.expanded;

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <Paper>
          <StepHeader>
            <Typography variant="h6">
              {t("approvalSteps.label", { stepNumber: 2 })}
            </Typography>
            <Typography style={{ fontWeight: "400" }} variant="h6">
              {t("approvalSteps.description")}
            </Typography>
          </StepHeader>
          {expanded && (
            <>
              <div style={{ padding: "24px" }}>
                <StepsList />
                <ConfirmButton
                  disabled={isConfirmDisabled(state)}
                  onClick={() =>
                    dispatch({ type: "confirmStep", payload: "approvalSteps" })
                  }
                />
              </div>
              <AdvancedOptions />
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

const isConfirmDisabled = (state: State) => {
  if (state.steps?.approvalSteps?.done) return true;
  return !state.steps.approvalSteps.valid;
};
