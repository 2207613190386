import { useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ButtonWithLoading from "components/ButtonWithLoading";
import ApplicationStatus, { SyncStatus } from "./ApplicationStatus";
import openAuthPopup, { isClosedByUser } from "../Accounts/openAuthPopup";
import { StorageSettingsUpdate } from "../types";

interface Props {
  settings: StorageSettingsUpdate;
  onAccountSync: (update: StorageSettingsUpdate) => void;
}

export default function OnedriveSync({ settings, onAccountSync }: Props) {
  const { t } = useTranslation("storage");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const { onedrive } = settings;
  const { installed } = onedrive;
  const appStatus = installed ? SyncStatus.INSTALLED : SyncStatus.NOT_INSTALLED;

  const installOneDrive = async () => {
    setLoading(true);
    try {
      const response = await openAuthPopup("ONEDRIVE_ORG");
      if (!response) return;

      onAccountSync({ ...settings, onedrive: { installed: true } });
    } catch (error) {
      if (!isClosedByUser(error)) {
        const errorCode = getErrorCode(error);
        let message = t("error.onedriveOrgInstallError");
        if (errorCode === "ACCESS_DENIED") {
          message = t("error.onedriveOrgAccessDenied");
        }
        enqueueSnackbar(message, { variant: "error" });
      }
    } finally {
      setLoading(false);
    }
  };

  if (installed === null) {
    return <div />;
  }

  return (
    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
      <ApplicationStatus status={appStatus} />
      <ButtonWithLoading
        variant="text"
        color="primary"
        data-testid="storage__ondedrive-install-btn"
        loading={loading}
        onClick={installOneDrive}
      >
        {installed ? t("reInstall") : t("install")}
      </ButtonWithLoading>
    </div>
  );
}

function getErrorCode(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  }
  return null;
}
