import {
  AccordionDetails,
  AccordionSummary,
  Typography,
  withStyles,
} from "@material-ui/core";
import styled from "styled-components";

export const StyledAccordionSummary = withStyles({
  expanded: {},
  content: {
    margin: "8px 0",
    "&$expanded": {
      margin: "8px 0",
    },
  },
})(AccordionSummary);

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: block;
  padding-bottom: 20px;
  padding-top: 10px;
`;

export const PropertyLabel = styled(Typography)`
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.82rem;
  font-weight: 500;
  margin-bottom: 4px;
  text-transform: uppercase;
`;

export const PropertyData = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
`;

export const EmailInputContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 30px;
  width: 100%;
`;

export const DateInputContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 20px;
  width: 100%;
`;
