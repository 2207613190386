import { useTranslation } from "react-i18next";
import { Link } from "@material-ui/core";
import { StatusDiv } from "./Styles";
import StatusIcon from "components/StatusIcon";
import { CSSProperties } from "react";

interface AccountStatusProps {
  synced: boolean;
  enableAccount: () => void;
}

export default function AccountStatus({
  synced,
  enableAccount,
}: AccountStatusProps) {
  const { t } = useTranslation("storage");

  if (synced)
    return (
      <StatusDiv data-testid="storage__synced-label">
        <StatusIcon state="enable" />
        {t("synced")}
      </StatusDiv>
    );

  const handleEnable = e => {
    e.preventDefault();
    enableAccount();
  };

  return (
    <StatusDiv data-testid="storage__not-synced-label">
      <StatusIcon state="disable" />
      {t("notSynced")}
      <Link
        href="#"
        color="primary"
        style={enableBtnStyles}
        onClick={handleEnable}
        data-testid="storage__enable-account-btn"
      >
        {t("enable")}
      </Link>
    </StatusDiv>
  );
}

const enableBtnStyles: CSSProperties = {
  marginLeft: 12,
  cursor: "pointer",
  fontSize: "0.8125rem",
  fontWeight: 500,
};
