import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Box, Button, TextField } from "@material-ui/core";
import ButtonWithLoading from "components/ButtonWithLoading";
import { getErrorCode } from "utils/apiRequestWithErrorCode";
import ConfirmWarning from "./ConfirmWarning";
import * as api from "../../api";
import openAuthPopup, {
  AccountWithEnterprise,
  isClosedByUser,
} from "../../Accounts/openAuthPopup";
import { StorageSettingsUpdate } from "../../types";
import { NotInstalled, SyncStatus } from "./Status";
import ConfirmDelete from "components/ConfirmDelete";
import * as Sentry from "@sentry/react";
import { getUser } from "utils/authentication";

enum LoadingStatus {
  Idle = "IDLE",
  Install = "INSTALL",
  Verify = "VERIFY",
  Remove = "REMOVE",
}

interface Props {
  settings: StorageSettingsUpdate;
  onAccountSync?: (update: StorageSettingsUpdate) => void;
}

export default function BoxSync({ settings, onAccountSync }: Props) {
  const { t } = useTranslation("storage");
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Idle);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [account, setAccount] = useState<AccountWithEnterprise>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [enterpriseId, setEnterpriseId] = useState<string>(
    settings?.box?.enterpriseId
  );
  const isInstalled = Boolean(enterpriseId);

  const verifyAndSaveBox = async (id: string) => {
    setLoading(LoadingStatus.Verify);
    try {
      const { valid } = await api.verifyBox(id);
      const updatedBox = { ...settings.box, installed: valid };
      onAccountSync({ ...settings, box: updatedBox });
      setEnterpriseId(id);
    } catch (error) {
      setLoading(LoadingStatus.Idle);

      Sentry.captureException(error, { user: getUser(), extra: { id } });

      switch (getErrorCode(error)) {
        case "INVALID_ENTERPRISE_ID":
          enqueueSnackbar(t("error.invalidEnterpriseId"), { variant: "error" });
          break;
        case "FORBIDDEN_STORAGE_ACCOUNT":
          enqueueSnackbar(t("error.forbiddenStorageAccount.message"), {
            variant: "error",
            persist: true,
            action: snackbarId => (
              <SettingBoxHelpOrClose
                onClose={() => closeSnackbar(snackbarId)}
              />
            ),
          });
          break;
        default:
          enqueueSnackbar(t("error.checkBoxSync"), { variant: "error" });
          break;
      }
    } finally {
      setLoading(LoadingStatus.Idle);
    }
  };

  const removeBoxIntegration = async () => {
    setLoading(LoadingStatus.Remove);
    try {
      const settings = await api.removeIntegration("box");
      onAccountSync(settings);
      setEnterpriseId("");
    } catch (e) {
      enqueueSnackbar(t("error.removeIntegration", { provider: "Box" }), {
        variant: "error",
      });
    } finally {
      setLoading(LoadingStatus.Idle);
    }
  };

  const handleAccountAuth = async () => {
    setLoading(LoadingStatus.Install);
    try {
      const account = await openAuthPopup("BOX");
      setAccount(account);
      setOpenModal(true);
    } catch (error) {
      if (isClosedByUser(error)) return;

      Sentry.captureException(error, { user: getUser() });
      enqueueSnackbar(t("box.confirmWarning.errors.auth"), {
        variant: "error",
      });
    } finally {
      setLoading(LoadingStatus.Idle);
    }
  };

  const handleCancelAccountAuth = () => {
    setOpenModal(false);
    setAccount(null);
  };

  const handleVerify = (id: string) => async e => {
    e.preventDefault();
    verifyAndSaveBox(id);
  };

  const handleConfirm = (id: string) => e => {
    verifyAndSaveBox(id);
    setOpenModal(false);
  };

  return (
    <Box
      display="flex"
      marginTop="20px"
      marginBottom="5px"
      alignItems="baseline"
    >
      {isInstalled && (
        <>
          <TextField
            variant="outlined"
            size="small"
            style={{ paddingRight: "10px" }}
            label={t("boxEnterpriseId")}
            value={enterpriseId}
            disabled={loading !== LoadingStatus.Idle}
            InputProps={{ disabled: true }}
            inputProps={{
              "data-testid": "storage__box-id-input",
            }}
          />
          <div style={{ display: "flex", gap: "10px" }}>
            <ButtonWithLoading
              disableElevation
              variant="text"
              color="primary"
              data-testid="storage__box-id-verify"
              loading={loading === LoadingStatus.Verify}
              disabled={loading !== LoadingStatus.Idle}
              onClick={handleVerify(enterpriseId)}
            >
              {t("verify")}
            </ButtonWithLoading>
            <ConfirmDelete
              color="secondary"
              message={t("removeIntegrationConfirm", { provider: "Box" })}
              label={t("remove")}
              onAccept={removeBoxIntegration}
            />
          </div>
          <SyncStatus installed={settings?.box?.installed} />
        </>
      )}

      {!isInstalled && (
        <NotInstalled
          loading={loading === LoadingStatus.Install}
          onClick={handleAccountAuth}
        />
      )}

      {openModal && (
        <ConfirmWarning
          isOpen={openModal}
          data={account}
          onCancel={handleCancelAccountAuth}
          onConfirm={handleConfirm}
        />
      )}
    </Box>
  );
}

function SettingBoxHelpOrClose({ onClose }) {
  const { t } = useTranslation("storage");

  return (
    <>
      <Button
        style={{ color: "white" }}
        onClick={() => {
          window.open(t("error.forbiddenStorageAccount.helpUrl"), "_blank");
        }}
      >
        {t("error.forbiddenStorageAccount.helpLabel")}
      </Button>
      <Button size="small" onClick={onClose}>
        {t("common:close")}
      </Button>
    </>
  );
}
