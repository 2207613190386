import { FilterPolicy } from "@dashboard-v3/api";
import { Rule } from "pages/Rules/types";
import useSWR from "swr";
import { fetcher } from "utils/apiRequestWithErrorCode";
import { Box, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";

type DetailsProp = {
  rule: Rule;
};

export default function FilterPolicyDetails({ rule }: DetailsProp) {
  const { t } = useTranslation("rules");
  const { data: filterPolicyOptions } = useSWR<FilterPolicy[]>(
    "/filter-policies",
    fetcher
  );

  if (!filterPolicyOptions) return null;

  const filterPolicy = filterPolicyOptions.find(
    filterPolicy => filterPolicy.id === rule.filterPolicyId
  );

  if (!filterPolicy) return null;

  return (
    <Box mt={2}>
      <Box>{t("filterPolicy.title")}</Box>
      <Link
        href={`/filter-policies/${filterPolicy.id}/edit`}
        target="_blank"
        rel="noreferrer noreferrer"
      >
        {filterPolicy.name}
      </Link>
    </Box>
  );
}
