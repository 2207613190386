/* eslint-disable  react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import SwitchField from "components/Forms/SwitchField";
import ButtonWithLoading from "components/ButtonWithLoading";
import PageLoading from "components/PageLoading";
import apiRequest from "utils/apiRequestWithErrorCode";
import { getFilename, getParsedDate } from "../utils";
import { MxheroShare, MxheroShareUpdate } from "@dashboard-v3/api";
import PasswordInput from "components/PaswordInput";
import { validatePassword } from "@dashboard-v3/shared";
import MalwareBanner from "./MalwareBanner";

export type FormState = {
  filename: string;
  expiresAt: string;
  canDownload: boolean;
  hasPassword: boolean;
  removePassword: boolean;
  showPasswordField: boolean;
  password: string;
  isSubmitting: boolean;
  malware: MxheroShare["malware"];
};

enum LoadingState {
  Initial = "INITIAL",
  Saving = "SAVING",
  Idle = "IDLE",
}

export default function StorageShareForm() {
  const { t } = useTranslation("storageShares");
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();
  const { id: paramId } = useParams();
  const [formState, setFormState] = useState<FormState>(toFormState());
  const [loading, setLoading] = useState<LoadingState>(LoadingState.Idle);

  useEffect(() => {
    async function fetchShareById(id: string) {
      setLoading(LoadingState.Initial);
      try {
        const share = await apiRequest<MxheroShare>(
          "GET",
          `/mxhero-shares/${id}`
        );
        setFormState(toFormState(share));
      } catch (e) {
        enqueueSnackbar(t("common:errors.fetchError"), {
          variant: "error",
        });
        history.push("/mxhero-shares");
      } finally {
        setLoading(LoadingState.Idle);
      }
    }

    async function loadFormValues() {
      if (state) {
        const { selectedShare, shareId } = state;
        return shareId !== paramId
          ? await fetchShareById(paramId)
          : setFormState(toFormState(selectedShare));
      }
      await fetchShareById(paramId);
    }

    loadFormValues();
  }, []);

  function handleClose() {
    history.push("/mxhero-shares");
  }

  function updateFormState(e) {
    const { name, value, checked } = e.target;

    if (name === "removePassword") {
      return setFormState(prev => ({
        ...prev,
        removePassword: checked,
        password: "",
        showPasswordField: false,
      }));
    }

    const isBooleanValue =
      name === "canDownload" || name === "showPasswordField";

    setFormState(prev => ({
      ...prev,
      [name]: isBooleanValue ? checked : value,
      isSubmitting: false,
    }));
  }

  async function handleSubmit() {
    setFormState({ ...formState, isSubmitting: true });

    if (formState.showPasswordField) {
      const [isValid] = validatePassword(formState.password);
      if (!isValid) return;
    }

    setLoading(LoadingState.Saving);
    try {
      await apiRequest<MxheroShare>(
        "PATCH",
        `/mxhero-shares/${paramId}`,
        toStorageShareUpdate(formState)
      );
    } catch (e) {
      enqueueSnackbar(t("form.fetchError"), { variant: "error" });
    } finally {
      setLoading(LoadingState.Idle);
      history.push("/mxhero-shares");
    }
  }

  return (
    <>
      <PageLoading loading={loading === LoadingState.Initial} />
      <Paper className={classes.mainContainer}>
        <Box mb={4}>
          <Typography variant="h6">{t("form.title")}</Typography>
          <Box style={{ fontSize: "1rem" }}>{formState.filename}</Box>
        </Box>
        {formState.malware && (
          <MalwareBanner shareId={paramId} malware={formState.malware} />
        )}
        <Box mb={3} className={classes.fieldsContainer}>
          <TextField
            name="expiresAt"
            type="date"
            label={t("form.fields.expiration.label")}
            variant="outlined"
            className={classes.textfield}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              max: dayjs().add(1, "year").format("YYYY-MM-DD"),
            }}
            fullWidth
            value={formState.expiresAt}
            onChange={updateFormState}
          />
          <SwitchField
            name="canDownload"
            label={t("form.fields.canDownload.label")}
            description={t("form.fields.canDownload.description")}
            style={{ marginTop: "15px" }}
            value={formState.canDownload}
            onChange={updateFormState}
          />
          <SwitchField
            name="showPasswordField"
            label={
              formState.hasPassword
                ? t("form.fields.showPasswordField.modifyLabel")
                : t("form.fields.showPasswordField.createLabel")
            }
            description={t("form.fields.showPasswordField.description")}
            style={{ marginTop: "15px" }}
            disabled={formState.removePassword}
            value={formState.showPasswordField}
            onChange={updateFormState}
          />
          {formState.showPasswordField && (
            <Box className={classes.textfield}>
              <PasswordInput
                value={formState.password}
                onChange={updateFormState}
                triggerValidation={formState.isSubmitting}
              />
            </Box>
          )}
          {formState.hasPassword && (
            <FormControlLabel
              name="removePassword"
              className={classes.checkbox}
              label={
                <Typography variant="caption" className={classes.checkboxLabel}>
                  {t("form.fields.removePassword.label")}
                </Typography>
              }
              control={
                <Checkbox
                  size="small"
                  color="primary"
                  checked={formState.removePassword}
                  onChange={updateFormState}
                />
              }
            />
          )}
        </Box>
        <Box mt={3} className={classes.buttonContainer}>
          <ButtonWithLoading
            loading={loading === LoadingState.Saving}
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleSubmit}
          >
            {t("common:save")}
          </ButtonWithLoading>
          <Button variant="text" disableElevation onClick={handleClose}>
            {t("common:cancel")}
          </Button>
        </Box>
      </Paper>
    </>
  );
}

const useStyles = makeStyles({
  textfield: { maxWidth: "70%" },
  checkbox: { marginTop: "15px" },
  checkboxLabel: { fontSize: "15px", lineHeight: 0 },
  mainContainer: { padding: 24, maxWidth: "80%" },
  fieldsContainer: { display: "flex", flexDirection: "column", gap: 10 },
  buttonContainer: {
    width: "100%",
    display: "flex",
    gap: 10,
    justifyContent: "flex-start",
  },
});

function toFormState(share?: MxheroShare): FormState {
  return {
    canDownload: share?.canDownload || false,
    expiresAt: getParsedDate(share?.expiresAt),
    filename: getFilename(share),
    hasPassword: share?.hasPassword || false,
    removePassword: false,
    password: "",
    showPasswordField: false,
    isSubmitting: false,
    malware: share?.malware || null,
  };
}

function toStorageShareUpdate(formState: FormState): MxheroShareUpdate {
  const update: MxheroShareUpdate = {
    canDownload: formState.canDownload,
    expiresAt: dayjs(formState.expiresAt).utc().valueOf(),
  };

  if (formState.password) {
    update.password = formState.password;
  }

  if (formState.removePassword) {
    update.removePassword = formState.removePassword;
  }

  return update;
}
