import {
  Step as StepperItem,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { State, Step } from "pages/Rules/CreateOrEdit/context/types";
import { Rule } from "pages/Rules/types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import StepDetails from "./StepDetails";
import steps from "./steps";
import StepTitle from "./StepTitle";

type Params = {
  rule: Rule;
  // Use null when the task is already complete
  currentStep?: State["currentStep"];
  // Use null when the task is already complete
  stepsState?: State["steps"];
};

export default function VerticalStepper({
  rule,
  currentStep,
  stepsState,
}: Params) {
  const { t } = useTranslation("rules");
  const isEdit = Boolean(rule?.id);
  const currentSteps = Object.keys(stepsState);
  const activeStep = isEdit ? 99 : currentSteps.indexOf(currentStep);

  return (
    <StyledStepper orientation="vertical" activeStep={activeStep}>
      {steps(rule, t).map(({ step, label }) => {
        return (
          <StepperItem
            key={step}
            completed={isStepComplete(step, stepsState)}
            expanded
          >
            <StepLabel>
              <StepType>{label}</StepType>
              <Typography color="primary" variant="subtitle2">
                <StepTitle rule={rule} step={step} activeStep={activeStep} />
              </Typography>
            </StepLabel>
            <StepContent>
              <StepDetails rule={rule} step={step} />
            </StepContent>
          </StepperItem>
        );
      })}
    </StyledStepper>
  );
}

function isStepComplete(step: Step, stepsState?: Params["stepsState"]) {
  if (!stepsState) return true;
  return stepsState[step].done;
}

const StyledStepper = styled(Stepper)`
  padding: 0;
`;

const StepType = styled("div")`
  font-weight: 500;
  font-size: 0.875rem;
`;
