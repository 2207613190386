import { useState } from "react";
import { useParams } from "react-router-dom";
import { OptionGroup } from "components/Styled";
import FilterFromTo from "./FilterFromTo";
import { useRuleContext } from "../../context/ruleContext";
import { typePath } from "../../context/helpers";
import { FromTo, MailboxFilter } from "@dashboard-v3/api";
import { Rule } from "pages/Rules/types";
import { RuleUrlParams } from "../..";
import IncludeFoldersFilter from "./FilterFolders/IncludeFoldersFilter";
import ExcludeFoldersFilter from "./FilterFolders/ExcludeFoldersFilter";
import produce from "immer";
import type { Draft } from "immer";
import FilterPolicyOption from "./FilterPolicyOption";

const filterInit = (): MailboxFilter => ({
  includeFromTo: null,
  excludeFromTo: null,
});

function getMailboxFilter(rule: Rule) {
  const typePathRule = typePath(rule.actionType, rule);
  if ("mailbox" in typePathRule) {
    const filter = typePathRule.mailbox?.filter;
    if (filter) {
      return {
        includeFromTo: filter.includeFromTo,
        excludeFromTo: filter.excludeFromTo,
      };
    }
  }
  return null;
}

export default function MailboxOptions() {
  const { state, dispatch } = useRuleContext();
  const { action }: RuleUrlParams = useParams();
  const { errors } = state.filters.targetFilter;
  const isEdit = action === "edit";

  const [filter, setFilter] = useState<MailboxFilter>(
    getMailboxFilter(state.rule) || filterInit()
  );

  function updateFilter(update: (draft: Draft<MailboxFilter>) => void) {
    const changes = produce(filter, update);
    setFilter(changes);
    dispatch({ type: "updateFilterTarget", payload: changes });
  }

  const handleFromTo = (type: "filter" | "exclude") => (val: FromTo[]) => {
    updateFilter(draft => {
      if (type === "filter") {
        draft.includeFromTo = val;
      }
      if (type === "exclude") {
        draft.excludeFromTo = val;
      }
    });
  };

  return (
    <>
      <OptionGroup>
        <FilterPolicyOption />
      </OptionGroup>
      <OptionGroup>
        <FilterFromTo
          type="filter"
          allowedTypes={["ACCOUNT", "ANYONE", "DOMAIN"]}
          value={filter.includeFromTo}
          errors={errors.inclusions}
          onChange={handleFromTo("filter")}
          disabled={isEdit}
        />
      </OptionGroup>
      <OptionGroup>
        <FilterFromTo
          type="exclude"
          allowedTypes={["ACCOUNT", "ANYONE", "DOMAIN"]}
          value={filter.excludeFromTo}
          errors={errors.exclusions}
          onChange={handleFromTo("exclude")}
          disabled={isEdit}
        />
      </OptionGroup>
      <OptionGroup>
        <IncludeFoldersFilter disabled={isEdit} />
      </OptionGroup>
      <OptionGroup>
        <ExcludeFoldersFilter disabled={isEdit} />
      </OptionGroup>
    </>
  );
}
