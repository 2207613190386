import { StorageAccount } from "@dashboard-v3/api";
import SelectField from "components/Forms/SelectField";
import { useTranslation } from "react-i18next";
import { StyledMenuItem } from ".";
import { Typography } from "@material-ui/core";
import { fetchStorageAccounts } from "pages/Integrations/api";
import useSWR from "swr";
import { Skeleton } from "@material-ui/lab";

export default function SelectStorageAccount({
  value,
  onChange: emitChange,
}: {
  value: string;
  onChange: (storageAccount: StorageAccount) => void;
}) {
  const { t } = useTranslation(["rules", "datasets"]);

  const { data: storageAccounts, isLoading } = useSWR<StorageAccount[]>(
    "/storage-accounts",
    fetchStorageAccounts
  );

  if (isLoading) return <Skeleton variant="rect" width="100%" height="55px" />;

  return (
    <SelectField
      allowEmpty={!value}
      value={value}
      required
      label={t("datasets:syncForm.cloudStorageAccount")}
      options={storageAccounts.filter(account => account.type !== "DROPBOX")}
      onChange={event => {
        const accountId = event.target.value as string;
        emitChange(storageAccounts.find(account => account.id === accountId));
      }}
      renderValue={value => {
        const account = storageAccounts.find(account => account.id === value);
        if (!account) return "";
        return account.type === "DROPBOX" ? account.data.name : account.user;
      }}
      renderOption={option => {
        return (
          <StyledMenuItem key={option.id} value={option.id}>
            <Typography variant="caption">
              {t(`cloudStorage.header.subtitle.${option.type}`)}
            </Typography>
            <Typography>
              {option.type === "DROPBOX" ? option.data.name : option.user}
            </Typography>
          </StyledMenuItem>
        );
      }}
    />
  );
}
