import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Drawer, List } from "@material-ui/core";
import { getUser } from "utils/authentication";
import SidebarSection from "./SidebarSection";
import SidebarItem from "./SidebarItem";
import CustomersReportLink from "./CustomersReportLink";
import SidebarStatusBtn from "./StatusBtn";
import { Logo, StyledToolbar, useStyles } from "./Styled";

export enum RouteGroup {
  RULES = "rules",
  REPORTS = "reports",
  SETTINGS = "settings",
  AUTOFILLING = "autoFilling",
}

export default function Sidebar() {
  const { t } = useTranslation("sidebar");
  const classes = useStyles();
  const [selectedGroup, setSelectedGroup] = useState<RouteGroup>(null);
  const { isPartner } = getUser();

  const handleGroupClick = (group: RouteGroup) => setSelectedGroup(group);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <StyledToolbar className={classes.toolbar}>
        <Logo>mxHERO</Logo>
      </StyledToolbar>
      <List dense component="nav">
        <SidebarSection
          group={RouteGroup.RULES}
          selected={selectedGroup}
          onClick={handleGroupClick}
        >
          <SidebarItem label={t("listRules")} route="/rules/list" />
          <SidebarItem label={t("createRule")} route="/rules/create" />
          <SidebarItem label={t("reprocessEmails")} route="/reprocess-emails" />
        </SidebarSection>
        <SidebarSection
          group={RouteGroup.REPORTS}
          selected={selectedGroup}
          onClick={handleGroupClick}
        >
          <SidebarItem label={t("tracing")} route="/tracing" />
          <SidebarItem label={t("notifications")} route="/notifications" />
          <SidebarItem label={t("userActivity")} route="/user-activity" />
          <SidebarItem label={t("usageReports")} route="/usage-reports" />
          <SidebarItem label={t("storageShares")} route="/mxhero-shares" />
          {isPartner && <CustomersReportLink />}
        </SidebarSection>
        <SidebarSection
          group={RouteGroup.SETTINGS}
          selected={selectedGroup}
          onClick={handleGroupClick}
        >
          <SidebarItem label={t("accountsAndGroups")} route="/accounts" />
          <SidebarItem label={t("billing")} route="/billing" />
          <SidebarItem label={t("gateway")} route="/gateway" />
          <SidebarItem
            label={t("notifications")}
            route="/notification-settings"
          />
          <SidebarItem
            label={t("organizationAndDomains")}
            route="/organization-and-domains"
          />
          <SidebarItem label={t("security")} route="/security" />
          <SidebarItem label={t("integrations")} route="/integrations" />
          <SidebarItem label={t("users")} route="/users" />
        </SidebarSection>
        <SidebarSection
          disableDivider
          group={RouteGroup.AUTOFILLING}
          selected={selectedGroup}
          onClick={handleGroupClick}
        >
          <SidebarItem label={t("copyPolicies")} route="/copy-policies" />
          <SidebarItem label={t("datasets")} route="/datasets" />
          <SidebarItem label={t("filterPolicies")} route="/filter-policies" />
          <SidebarItem
            label={t("securityDefinitions")}
            route="/security-definitions"
          />
          <SidebarItem
            label={t("securityPolicies")}
            route="/security-policies"
          />
          <SidebarItem
            label={t("templateDefinitions")}
            route="/template-definitions"
          />
          <SidebarItem
            label={t("templatePolicies")}
            route="/template-policies"
          />
          <SidebarItem label={t("variables")} route="/variables" />
        </SidebarSection>
      </List>
      <SidebarStatusBtn />
    </Drawer>
  );
}

export const sectionRoutes: Record<RouteGroup, string[]> = {
  rules: ["/rules", "/reprocess-emails"],
  reports: [
    "/tracing",
    "/user-activity",
    "/usage-reports",
    "/notifications",
    "/mxhero-shares",
  ],
  settings: [
    "/accounts",
    "/billing",
    "/domains",
    "/gateway",
    "/notification-settings",
    "/organization-and-domains",
    "/security",
    "/integrations",
    "/users",
  ],
  autoFilling: [
    "/copy-policies",
    "/datasets",
    "/filter-policies",
    "/security-policies",
    "/security-definitions",
    "/template-policies",
    "/variables",
    "/template-definitions",
  ],
};
