import { useTranslation } from "react-i18next";
import {
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Accordion } from "components/AccordionForm";
import ReportItem from "./ReportItem";

import { TracingReportsResponse } from "@dashboard-v3/api";

interface ReportListProps {
  data: TracingReportsResponse;
  expanded: boolean;
  onExpand: () => void;
  loading: boolean;
  page: number;
  onPageChange: (page: number) => void;
  onCancelReport: (id: string) => void;
}

export default function ReportList(props: ReportListProps) {
  const { t } = useTranslation("tracing");
  const classes = useStyles();
  const { data, expanded, onExpand, loading, page, onPageChange } = props;
  const { reports, total, pageSize } = data;

  const getPaginationLabel = context => {
    const { count, page } = context;
    const total = Math.ceil(count / pageSize);
    return t("reports.table.pagination.label", { page: page + 1, total });
  };

  console.log();

  return (
    <>
      <Accordion
        className={classes.accordion}
        expanded={expanded}
        onChange={onExpand}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle2">{t("reports.title")}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {t("reports.table.header.created")}
                </TableCell>
                <TableCell align="left">
                  {t("reports.table.header.status")}
                </TableCell>
                <TableCell align="left">
                  {t("reports.table.header.user")}
                </TableCell>
                <TableCell align="left">
                  {t("reports.table.header.filters")}
                </TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.map(item => (
                <ReportItem
                  key={item.id}
                  report={item}
                  loading={loading}
                  onCancelReport={props.onCancelReport}
                />
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={3}>{t("reports.expiration")}</TableCell>
                <TablePagination
                  page={page - 1}
                  count={total}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={[]}
                  labelDisplayedRows={getPaginationLabel}
                  onPageChange={(_, page) => onPageChange(page + 1)}
                  backIconButtonProps={{ size: "small" }}
                  nextIconButtonProps={{
                    size: "small",
                    className: classes.nextButton,
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

const useStyles = makeStyles({
  accordion: { marginBottom: "10px" },
  nextButton: { marginRight: 10, marginLeft: 5 },
  accordionDetails: { padding: 0 },
  table: {
    "& tbody tr:last-child td": {
      border: 0,
    },
  },
});
