import { Draft } from "immer";
import { State, Step } from "../../types";
import validateAction from "./action";
import validateTarget from "./target";
import validateCloudStorage from "./cloudStorage";
import validateStorageFilepath from "./storageFilepath";
import validateApprovalSteps from "./approvalSteps";
import { verifyIfCanSave } from "../verifyIfCanSave";

export default function validateStep(step: Step, draft: Draft<State>) {
  switch (step) {
    case "action":
      validateAction(draft);
      verifyIfCanSave(draft);
      break;
    case "target":
      validateTarget(draft);
      verifyIfCanSave(draft);
      break;
    case "cloudStorage":
      validateCloudStorage(draft);
      verifyIfCanSave(draft);
      break;
    case "storageFilepath":
      validateStorageFilepath(draft);
      verifyIfCanSave(draft);
      break;
    case "approvalSteps":
      validateApprovalSteps(draft);
      verifyIfCanSave(draft);
      break;
  }
}
