import produce from "immer";
import { getStorage } from "pages/Rules/CloudStorage/utils";
import { SecurityOption } from "pages/Rules/CloudStorage/types";
import { getEmailFlow } from "../helpers";

import { StorageProvider, TargetType } from "@dashboard-v3/api";
import { State } from "../types";

export type CheckFolderCollaboration = {
  type: "checkFolderCollaboration";
  payload: {
    targetType?: TargetType;
    provider?: StorageProvider;
    securityOption?: SecurityOption;
    allManagedUserIds?: boolean;
    smtpPhase?: boolean;
  };
};

export function checkFolderCollaboration(
  state: State,
  payload: CheckFolderCollaboration["payload"]
): State {
  return produce(state, draft => {
    const { targetType } = state.rule;
    const storage = getStorage(state.rule);
    const emailFlow = getEmailFlow(state.rule);

    draft.checks.canCollaborateFolder =
      isValidTarget(targetType, payload) &&
      isValidSMTPPhase(emailFlow?.smtpPhase, payload) &&
      isValidProvider(storage?.provider, payload) &&
      isValidSecurityOption(payload) &&
      isValidManagedUserIds(storage?.allManagedUserIds, payload);
  });
}

const isValidTarget = (
  currentTarget: TargetType,
  payload: CheckFolderCollaboration["payload"]
) => {
  const validTargets = ["MAIL_BOX", "EMAIL_FLOW"];
  if (payload.targetType) return validTargets.includes(payload.targetType);
  return validTargets.includes(currentTarget);
};

const isValidProvider = (
  currentProvider: StorageProvider,
  payload: CheckFolderCollaboration["payload"]
) => {
  if (payload.provider) return payload.provider !== "EGNYTE";
  return currentProvider !== "EGNYTE";
};

const isValidSecurityOption = (
  payload: CheckFolderCollaboration["payload"]
) => {
  if (payload.securityOption) {
    const { allowFolderCollaboration } = payload.securityOption;
    return allowFolderCollaboration;
  }
  return true;
};

const isValidManagedUserIds = (
  current: boolean,
  payload: CheckFolderCollaboration["payload"]
) => {
  if ("allManagedUserIds" in payload) return !payload.allManagedUserIds;
  return !current;
};

const isValidSMTPPhase = (
  current: boolean,
  payload: CheckFolderCollaboration["payload"]
) => {
  if ("smtpPhase" in payload) return !payload.smtpPhase;
  return !current;
};
