import produce from "immer";
import { Option } from "pages/Rules/FromToSelector/types";
import { State } from "../types";

export type CheckUnmanagedFlow = {
  type: "checkUnmanagedFlow";
  payload: { to: Option; from: Option; smtpPhase?: boolean };
};

export const checkUnmanagedFlow = (
  state: State,
  payload: CheckUnmanagedFlow["payload"]
) => {
  return produce(state, draft => {
    if (isUnmanagedFlow(payload)) {
      draft.checks.emailFlow.error = "UNMANAGED_FLOW";
      draft.steps.target.valid = false;
    } else if (!isValidForSMTP(payload)) {
      draft.checks.emailFlow.error = "UNMANAGED_FROM";
      draft.steps.target.valid = false;
    } else {
      draft.checks.emailFlow.error = null;
    }
    // Set for use in canTrackEmails
    draft.checks.emailFlow.fromOption = payload.from;
  });
};

const isUnmanagedFlow = (payload: CheckUnmanagedFlow["payload"]) => {
  if (payload.from && payload.to) {
    const { from, to } = payload;
    return !from.managed && !to.managed;
  }
  return false;
};

const isValidForSMTP = (payload: CheckUnmanagedFlow["payload"]) => {
  if (payload.from && payload?.smtpPhase) {
    const { from } = payload;
    return from.managed;
  }

  return true;
};
