import { useTranslation } from "react-i18next";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { OptionTitle } from "components/Styled";
import { CreateForm } from ".";

export default function FileOrFolder({
  value,
  onChange: emitChange,
}: {
  value: CreateForm["params"]["type"];
  onChange: (value: CreateForm["params"]["type"]) => void;
}) {
  const { t } = useTranslation("datasets");

  return (
    <>
      <FormControl>
        <OptionTitle>{t("syncForm.fileOrFolderTitle")}</OptionTitle>
      </FormControl>
      <RadioGroup
        aria-label="action"
        name="action"
        value={value}
        onChange={event => {
          const newValue = event.target.value as "FILE" | "FOLDER";
          emitChange(newValue);
        }}
      >
        <Box display="flex" mt=".5em" gridGap="20px">
          <FormControlLabel
            value="FILE"
            control={<Radio color="primary" />}
            label={t("syncForm.files")}
          />
          <FormControlLabel
            value="FOLDER"
            control={<Radio color="primary" />}
            label={t("syncForm.folders")}
          />
        </Box>
      </RadioGroup>
    </>
  );
}
