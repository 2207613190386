import { useTranslation } from "react-i18next";
import Confirm, { Props as ConfirmProps } from "./Confirm";
import { Button, ButtonProps } from "@material-ui/core";

type Props = { label?: string | React.ReactElement } & Omit<
  ConfirmProps & ButtonProps,
  "button" | "children" | "onClick" | "actionable"
>;

export default function ConfirmDelete(props: Props) {
  const { message, onAccept, label, ...btnProps } = props;
  const { t } = useTranslation("common");

  return (
    <Confirm
      {...props}
      actionable={openConfirm => (
        <Button
          data-testid="components__delete-btn"
          {...btnProps}
          onClick={openConfirm}
        >
          {label || t("common:delete")}
        </Button>
      )}
    />
  );
}
