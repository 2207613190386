import { useTranslation } from "react-i18next";
import { DataSetFieldContainer, StyledTextField } from "pages/Datasets/Styled";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { Error, KeyValueItem } from "./types";

type ItemParams = {
  item: KeyValueItem;
  onChange: (fieldName: string, fieldValue: string) => void;
  onRemove: () => void;
  canDelete: boolean;
  error?: Error[string];
};

export default function Item({
  item,
  onChange: emit,
  onRemove,
  canDelete,
  error,
}: ItemParams) {
  const { t } = useTranslation("datasets");

  const errorKeyHint = () => {
    if (error?.key === "isBlank") return t("form.fields.key.errors.empty");
    if (error?.key === "alreadyExist")
      return t("form.fields.key.errors.duplicatedKey");
    return "";
  };

  const errorValueHint =
    error?.value === "isBlank" ? t("form.fields.value.errors.empty") : "";

  return (
    <DataSetFieldContainer key={`${item.id}_item`}>
      <StyledTextField
        inputProps={{ "data-testid": "datasets_form-key-input" }}
        name="key"
        label={t("form.fields.key.label")}
        variant="outlined"
        fullWidth
        value={item.key}
        error={Boolean(error?.key)}
        helperText={errorKeyHint()}
        onChange={event => emit(event.target.name, event.target.value)}
      />
      <StyledTextField
        inputProps={{ "data-testid": "datasets_form-value-input" }}
        name="value"
        label={t("form.fields.value.label")}
        variant="outlined"
        fullWidth
        value={item.value}
        error={Boolean(error?.value)}
        helperText={errorValueHint}
        onChange={event => {
          emit(event.target.name, event.target.value);
        }}
      />
      <IconButton
        size="small"
        aria-label="delete"
        disabled={!canDelete}
        onClick={onRemove}
      >
        <DeleteIcon />
      </IconButton>
    </DataSetFieldContainer>
  );
}
