import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Grid, Paper, Typography } from "@material-ui/core";
import DescriptionBlock from "components/DescriptionBlock";
import ArchivingAddressOptions from "./ArchivingAddress";
import EmailFlowOptions from "./EmailFlow";
import MailboxOptions from "./Mailbox";
import { OptionGroup } from "components/Styled";
import { Rule, RuleType } from "pages/Rules/types";
import TargetOption from "./TargetOption";
import { useRuleContext } from "../../context/ruleContext";
import {
  getArchivingAddress,
  getEmailFlow,
  getMailbox,
  getStorageWebhook,
} from "../../context/helpers";
import { RuleUrlParams } from "../..";
import StorageWebhookOptions from "./StorageWebhook";

const StepHeader = styled.header`
  border-bottom: 1px solid #d6dbe0;
  padding: 14px 24px;
`;

const targetsByRule: Record<RuleType, Rule["targetType"][]> = {
  COPY_EMAILS: ["ARCHIVING_ADDRESS", "EMAIL_FLOW", "MAIL_BOX"],
  MOVE_EMAILS: ["MAIL_BOX"],
  PROTECT_EMAILS: ["EMAIL_FLOW"],
  REPLACE_ATTACHMENTS: ["EMAIL_FLOW", "MAIL_BOX"],
  BLOCK_ATTACHMENTS: ["EMAIL_FLOW"],
  DRAG_AND_DROP: ["STORAGE_WEBHOOK"],
  REQUEST_APPROVAL: ["EMAIL_FLOW"],
};

function cannotChangeTarget(rule: Rule, action: RuleUrlParams["action"]) {
  return Boolean(rule.id) && action === "edit";
}

const TargetStep = () => {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const { action }: RuleUrlParams = useParams();
  const expanded = state.steps["target"].expanded;
  const targetsEnabled = targetsByRule[state.type];
  const rule = state.rule;
  const targetType = rule.targetType;
  const stepTitlePrefix = rule.actionType === "REQUEST_APPROVAL" ? "1." : "2.";

  const changeTargetType = (newTargetType: Rule["targetType"]) => {
    dispatch({
      type: "checkFolderCollaboration",
      payload: { targetType: newTargetType },
    });
    dispatch({ type: "changeTargetType", payload: newTargetType });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <Paper>
          <StepHeader>
            <Typography variant="h6">
              {t("steps.target.title", { prefix: stepTitlePrefix })}
            </Typography>
            {targetType ? (
              <Typography color="primary" variant="h6">
                {t(targetType)}
              </Typography>
            ) : (
              <Typography style={{ fontWeight: "400" }} variant="h6">
                {t("selectTheScope")}
              </Typography>
            )}
          </StepHeader>
          {expanded ? (
            <>
              <OptionGroup style={{ marginBottom: "0", padding: "24px" }}>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  style={{ fontWeight: "500" }}
                >
                  {t("ruleScope")}:
                </Typography>
                <Grid container spacing={3}>
                  <TargetOption
                    disabled={cannotChangeTarget(rule, action)}
                    target="ARCHIVING_ADDRESS"
                    targetsEnabled={targetsEnabled}
                    selection={targetType}
                    onClick={changeTargetType}
                  />

                  <TargetOption
                    disabled={cannotChangeTarget(rule, action)}
                    target="EMAIL_FLOW"
                    targetsEnabled={targetsEnabled}
                    selection={targetType}
                    onClick={changeTargetType}
                  />

                  <TargetOption
                    disabled={cannotChangeTarget(rule, action)}
                    target="MAIL_BOX"
                    targetsEnabled={targetsEnabled}
                    selection={targetType}
                    onClick={changeTargetType}
                  />

                  <TargetOption
                    disabled={cannotChangeTarget(rule, action)}
                    target="STORAGE_WEBHOOK"
                    targetsEnabled={targetsEnabled}
                    selection={targetType}
                    onClick={changeTargetType}
                  />
                </Grid>

                {targetType && (
                  <DescriptionBlock title={`${t(targetType)}`}>
                    {t(`targetStep.description.${targetType}`)}
                  </DescriptionBlock>
                )}
              </OptionGroup>

              {isActiveTarget("ARCHIVING_ADDRESS", rule) && (
                <ArchivingAddressOptions />
              )}
              {isActiveTarget("EMAIL_FLOW", rule) && <EmailFlowOptions />}
              {isActiveTarget("MAIL_BOX", rule) && <MailboxOptions />}
              {isActiveTarget("STORAGE_WEBHOOK", rule) && (
                <StorageWebhookOptions />
              )}
            </>
          ) : null}
        </Paper>
      </Grid>
    </Grid>
  );
};

function isActiveTarget(targetType: Rule["targetType"], rule: Rule) {
  if (rule.targetType === targetType) {
    switch (targetType) {
      case "ARCHIVING_ADDRESS":
        return Boolean(getArchivingAddress(rule));
      case "EMAIL_FLOW":
        return Boolean(getEmailFlow(rule));
      case "MAIL_BOX":
        return Boolean(getMailbox(rule));
      case "STORAGE_WEBHOOK":
        return Boolean(getStorageWebhook(rule));
    }
  }
  return false;
}

export default TargetStep;
