/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Skeleton } from "@material-ui/lab";
import ComponentList from "components/ComponentList";
import { ShowMoreBtn } from "components/List";
import FilterPolicyItem from "./FilterPolicyItem";
import apiRequest from "utils/apiRequestWithErrorCode";
import { byLastModified } from "utils/sort";

import { FilterPolicy } from "@dashboard-v3/api";

const maxItems = 10;

type Status = "IDLE" | "LOADING_MORE" | "LOADING_ALL" | "LOADING_ITEM";

type ListState = {
  loading: Status;
  loadingItem: string | null;
  hasMore: boolean;
  offset: number;
  policies: FilterPolicy[] | null;
};

export type ListStateUpdate = {
  [K in keyof ListState]?:
    | ListState[K]
    | ((prevValue: ListState[K]) => ListState[K]);
};

export default function FilterPolicyList() {
  const { t } = useTranslation("filterPolicies");
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<ListState>(initList);
  const { policies, loading, loadingItem, hasMore } = state;

  function updateState(listUpdate: ListStateUpdate) {
    setState(prev => {
      const update = Object.keys(listUpdate).reduce((acc, key) => {
        return typeof listUpdate[key] === "function"
          ? { ...acc, [key]: listUpdate[key](prev[key]) }
          : { ...acc, [key]: listUpdate[key] };
      }, {} as ListState);
      return { ...prev, ...update };
    });
  }

  async function fetchPolicies(offset = 0) {
    try {
      const path = `/filter-policies?offset=${offset}&limit=${maxItems}`;
      const items = await apiRequest<FilterPolicy[]>("GET", path);

      updateState({
        hasMore: items.length === maxItems,
        policies: prev => {
          const newPolicies = offset ? [...items, ...prev] : items;
          return newPolicies.sort(byLastModified);
        },
      });
    } catch (error) {
      enqueueSnackbar(t("common:errors.fetchError"), { variant: "error" });
    }
  }

  useEffect(() => {
    async function initialFetch() {
      updateState({ loading: "LOADING_ALL" });
      await fetchPolicies();
      updateState({ loading: "IDLE" });
    }
    if (!policies) {
      initialFetch();
    }
  }, []);

  const loadMore = async () => {
    updateState({ loading: "LOADING_MORE" });
    const offset = state.offset + maxItems;
    await fetchPolicies(offset);
    updateState({ offset, loading: "IDLE" });
  };

  return (
    <>
      <ComponentList<FilterPolicy>
        list={policies}
        emptyMsg={t("list.emptyList")}
        loading={loading === "LOADING_ALL"}
        renderItems={policy =>
          loadingItem === policy.id ? (
            <Skeleton
              key={policy.id}
              height="4rem"
              animation="wave"
              variant="rect"
            />
          ) : (
            <FilterPolicyItem
              key={policy.id}
              policy={policy}
              updateListState={updateState}
              fetchPolicies={fetchPolicies}
            />
          )
        }
      />
      {hasMore && (
        <ShowMoreBtn loading={loading === "LOADING_MORE"} onClick={loadMore} />
      )}
    </>
  );
}

const initList = (): ListState => ({
  loading: "LOADING_ALL",
  loadingItem: null,
  hasMore: false,
  offset: 0,
  policies: null,
});
