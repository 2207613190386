/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { usePlatformVariables } from "./usePlatformVariables";
import apiRequest from "./apiRequestWithErrorCode";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { byLastModified } from "./sort";
import useSWR, { mutate } from "swr";
import { KeyedVariable, Variable, VariableReqBody } from "@dashboard-v3/api";
import { Rule } from "pages/Rules/types";
import { DashboardVariable } from "types";
import { deepClone } from "@dashboard-v3/shared";

export default function useVariables(rule?: Rule) {
  const { t } = useTranslation("variables");
  const { enqueueSnackbar } = useSnackbar();
  const [variableOptions, setVariableOptions] = useState<DashboardVariable[]>();
  const { variables: platformVariables } = usePlatformVariables();
  const [userVariables, setUserVariables] = useState<KeyedVariable[]>();

  const { data, isLoading, error } = useSWR(
    "/variables",
    async () => {
      return Promise.all([
        apiRequest<KeyedVariable[]>("GET", "/platform/variables"),
        apiRequest<KeyedVariable[]>("GET", "/variables?limit=1000"),
      ]);
    },
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    if (error) {
      enqueueSnackbar(t(`errors.variables.fetchListError`), {
        variant: "error",
      });
      return;
    }

    if (data) {
      const [defaultVars, userVars] = data;
      const list = [...defaultVars, ...userVars];
      const sorted = list.sort(byLastModified);
      setUserVariables(sorted);
      setVariableOptions(
        sorted.map(toUserVariableOption).concat(platformVariables)
      );
    }
  }, [data, error]);

  useEffect(() => {
    const enabledPlatformVars = rule
      ? platformVariables.filter(v => byActionAndTarget(v, rule))
      : platformVariables;

    const userVars = (userVariables || []).map(toUserVariableOption);
    setVariableOptions(userVars.concat(enabledPlatformVars));
  }, [rule?.actionType, rule?.targetType, userVariables]);

  const deleteUserVariable = async (
    id: string,
    onError: (error: unknown) => void
  ) => {
    try {
      await mutate(
        "/variables",
        apiRequest<void>("DELETE", `/variables/${id}`),
        {
          populateCache: () => {
            const list = deepClone(userVariables);
            const index = list.findIndex(variable => variable.id === id);
            list.splice(index, 1);
            return splitList(list);
          },
          revalidate: false,
        }
      );
    } catch (error) {
      onError(error);
    }
  };

  async function createUserVariable(body: VariableReqBody) {
    try {
      await mutate(
        { path: "/variables" },
        () => apiRequest<Variable>("POST", "/variables", body),
        {
          optimisticData: () => {
            const list = deepClone(userVariables);
            list.push({ ...body, updatedAt: Date.now() } as KeyedVariable);
            return splitList(list);
          },
        }
      );
    } catch (error) {
      enqueueSnackbar(t("errors.variables.saveError"), {
        variant: "error",
      });
    }
  }

  async function updateUserVariable(id: string, body: VariableReqBody) {
    try {
      await mutate(
        { path: "/variables", id },
        () => apiRequest<KeyedVariable>("PUT", `/variables/${id}`, body),
        {
          populateCache(updatedVariable) {
            const list = deepClone(userVariables);
            const index = list.findIndex(variable => variable.id === id);
            list[index] = updatedVariable;
            return splitList(list);
          },
          revalidate: false,
        }
      );
    } catch (error) {
      enqueueSnackbar(t("errors.variables.saveError"), {
        variant: "error",
      });
    }
  }

  /**
   * Map variable ID to Label
   */
  const mapVarToLabel = (
    input: string,
    withCustomVars?: DashboardVariable[]
  ) => {
    const variables = withCustomVars || platformVariables;
    if (input) {
      return input
        .split(/(\{.*?\})/g)
        .filter(str => str)
        .map(str => {
          const match = variables.find(v => str === v.id);
          return match ? t(`platformVariables:${match.name}`) : str;
        })
        .join("");
    }
    return "";
  };

  /**
   * Map label to variable ID
   */
  const mapVarToValue = (
    label: string,
    withCustomVars?: DashboardVariable[]
  ) => {
    const variables = withCustomVars || platformVariables;

    if (label) {
      return label.replace(/\{.*?}/g, match => {
        const value = variables.find(s => {
          return match === t(`platformVariables:${s.name}`);
        });
        return value ? value.id : match;
      });
    }
    return label;
  };

  return {
    variableOptions,
    platformVariables,
    userVariables,
    updateUserVariable,
    createUserVariable,
    deleteUserVariable,
    mapVarToLabel,
    mapVarToValue,
    loading: isLoading,
  };
}

export const toUserVariableOption = (variable: Variable): DashboardVariable => {
  if (variable) {
    return {
      id: `{${variable.id}}`,
      name: `{${variable.name}}`,
      description: variable.description,
      origin: variable.platformVariable ? "DEFAULT" : "USER",
    };
  }
};

export const byActionAndTarget = (variable: DashboardVariable, rule: Rule) => {
  const { actionType, targetType } = rule;
  const { actions, target } = variable;
  if (!actions.length && !target.length) return true;

  const actionIncluded = actions.includes(actionType) || actions.length === 0;
  const targetIncluded = target.includes(targetType) || target.length === 0;

  return actionIncluded && targetIncluded;
};

function splitList(list: Variable[]) {
  return list.reduce(
    (lists, variable) => {
      if (variable.platformVariable) {
        lists[0].push(variable);
      } else {
        lists[1].push(variable);
      }
      return lists;
    },
    [[], []]
  );
}
