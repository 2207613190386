import { CSVFile } from "pages/Datasets/types";
import formatBytes from "utils/formatBytes";
import { isBlank } from "utils/string";
import { produce } from "immer";
import random from "utils/random";
import { FormHelperText, List, ListItem, Tooltip } from "@material-ui/core";
import TextFileInput from "../TextFileInput";
import ButtonWithLoading from "components/ButtonWithLoading";
import InfoSharpIcon from "@material-ui/icons/InfoSharp";
import { useTranslation } from "react-i18next";
import { State } from "./types";

type Props = {
  state: State;
  onChange: (updatedState: State) => void;
};

export default function UploadFileBtn({ state, onChange }: Props) {
  const { t } = useTranslation("datasets");

  const handleFileUpload = (file: CSVFile) => {
    const { content, size } = file;
    const COMMA_SEPARATED = /\w+,\w+/g;
    if (isBlank(content) || !COMMA_SEPARATED.test(content)) {
      return t("form.fields.datasetFile.errors.content");
    }
    if (size >= 2097152) {
      return t("form.fields.datasetFile.errors.size", {
        size: formatBytes(size),
      });
    }

    const updatedState = produce(state, draft => {
      content.split("\n").forEach(set => {
        const [key, value] = set.trim().split(",");

        if (isBlank(key)) return;

        const duplicatedIndex = draft.items.findIndex(item => item.key === key);
        if (duplicatedIndex > -1) {
          draft.items.splice(duplicatedIndex, 1);
          // remove from errors
          delete draft.errors[duplicatedIndex];
        }
        const newId = random();

        // prepend
        draft.items.unshift({ id: newId, key, value });

        if (isBlank(value)) {
          draft.errors[newId] = { value: "isBlank" };
        }
      });
    });

    onChange(updatedState);
  };

  return (
    <Tooltip
      title={
        <List dense disablePadding>
          <ListItem disableGutters>
            {t("form.tooltip.datasetFile.type")}
          </ListItem>
          <ListItem disableGutters>
            {t("form.tooltip.datasetFile.size")}
          </ListItem>
          <ListItem disableGutters>
            {t("form.tooltip.datasetFile.format")}
          </ListItem>
          <ListItem disableGutters>
            {t("form.tooltip.datasetFile.columns")}
          </ListItem>
        </List>
      }
    >
      <span>
        <TextFileInput
          name="datasetFile"
          accept=".csv"
          onFileUpload={handleFileUpload}
          renderAs={({ isUploading, uploadError }) => (
            <>
              <ButtonWithLoading
                data-testid="datasets_form-upload-file-btn"
                variant="outlined"
                color="primary"
                size="small"
                endIcon={<InfoSharpIcon />}
                loading={isUploading}
                onClick={() => {}}
                component="span"
              >
                {t("datasets:uploadFile")}
              </ButtonWithLoading>
              {Boolean(uploadError) && (
                <FormHelperText error>{uploadError}</FormHelperText>
              )}
            </>
          )}
        />
      </span>
    </Tooltip>
  );
}
