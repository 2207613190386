import { v4 as keyGen } from "uuid";
import apiRequest from "utils/apiRequestWithErrorCode";

import { Group, Variable } from "@dashboard-v3/api";
import { ReviewerOption } from "./types";
import { fetchAccounts } from "utils/api/accounts";

export async function fetchReviewerOptions(): Promise<ReviewerOption[]> {
  const [accounts, tags, groups, variables] = await Promise.all([
    getAccounts(),
    fetchTags(),
    fetchGroups(),
    fetchVariables(),
  ]);

  const searchOpt: ReviewerOption = {
    key: keyGen(),
    type: "SEARCH",
    label: "approvalSteps.optionSearch.label",
    value: `SEARCH:SEARCH`,
  };

  return [searchOpt, ...accounts, ...tags, ...groups, ...variables];
}

async function fetchTags(): Promise<ReviewerOption[]> {
  const tags = await apiRequest<string[]>("GET", "/accounts/tags");
  return tags.map<ReviewerOption>(tag => ({
    key: keyGen(),
    type: "TAG",
    label: tag,
    value: `${tag}:TAG`,
  }));
}

async function getAccounts(): Promise<ReviewerOption[]> {
  const accounts = await fetchAccounts({
    limit: 100000,
    type: "member",
  });
  return accounts.map(({ email }) => ({
    key: keyGen(),
    type: "EMAIL",
    label: email,
    value: `${email}:EMAIL`,
  }));
}

async function fetchGroups(): Promise<ReviewerOption[]> {
  const groups = await apiRequest<Group[]>("GET", `/groups`);
  return groups.map<ReviewerOption>(({ id, name }) => ({
    key: keyGen(),
    type: "GROUP",
    label: name,
    value: `${id}:GROUP`,
  }));
}

async function fetchVariables(): Promise<ReviewerOption[]> {
  const variables = await apiRequest<Variable[]>("GET", "/variables");
  return variables.map<ReviewerOption>(({ id, name }) => ({
    key: id,
    type: "VARIABLE",
    label: name,
    value: `${id}:VARIABLE`,
  }));
}
