import { useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ButtonWithLoading from "components/ButtonWithLoading";
import ApplicationStatus, { SyncStatus } from "../ApplicationStatus";
import { StorageSettingsUpdate } from "../../types";
import useMailboxAuthPopup from "utils/useMailboxAuthPopup";
import { Button } from "@material-ui/core";
import VerifyAccountModal from "./VerifyAccountModal";

interface Props {
  settings: StorageSettingsUpdate;
  onAccountSync: (update: StorageSettingsUpdate) => void;
}

export default function OfficeMailboxSync({ settings, onAccountSync }: Props) {
  const { t } = useTranslation("storage");
  const { enqueueSnackbar } = useSnackbar();
  const { open, isClosedByUser } = useMailboxAuthPopup();
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { office365 } = settings;

  const status = office365.mailbox
    ? SyncStatus.INSTALLED
    : SyncStatus.NOT_INSTALLED;

  const onAddOrgMailboxAccount = async () => {
    setLoading(true);
    try {
      const res = await open("office365/organization");
      if (!res.auth) return;

      onAccountSync({
        ...settings,
        office365: { ...settings.office365, mailbox: true },
      });
    } catch (error) {
      if (isClosedByUser(error)) return;

      enqueueSnackbar(t("officeMailboxSync.addError"), { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const onVerify = () => setIsModalOpen(true);

  return (
    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
      <ApplicationStatus status={status} />
      {status === SyncStatus.NOT_INSTALLED && (
        <ButtonWithLoading
          variant="text"
          color="primary"
          loading={loading}
          onClick={onAddOrgMailboxAccount}
        >
          {t("install")}
        </ButtonWithLoading>
      )}
      {status === SyncStatus.INSTALLED && (
        <Button variant="text" color="primary" onClick={onVerify}>
          {t("verify")}
        </Button>
      )}
      {isModalOpen && (
        <VerifyAccountModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
}
