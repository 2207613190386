import { Draft } from "immer";
import { State } from "../../types";
import { getApprovalSteps } from "../../helpers";
import { isBlank } from "utils/string";
import { SenderNotification, SupervisorNotification } from "@dashboard-v3/api";

export default function validate(draft: Draft<State>) {
  const { supervisors, senderNotification, supervisorNotification } =
    getApprovalSteps(draft.rule);

  if (!supervisors.length) {
    draft.steps.approvalSteps.valid = false;
    return;
  }

  const isValidSenderSettings = validateSettings(senderNotification);
  const isValidSupervisorSettings = validateSettings(supervisorNotification);
  const isValidSupervisors = !supervisors.some(
    s => isBlank(s.entity) || isBlank(s.type)
  );

  draft.steps.approvalSteps.valid =
    isValidSupervisors && isValidSenderSettings && isValidSupervisorSettings;
}

function validateSettings(
  notification: SenderNotification | SupervisorNotification
) {
  for (let key in notification) {
    const value = notification[key];
    const isString = typeof value === "string";
    const shouldValidate = isString && isBlank(value);

    if (shouldValidate) {
      if (!key.endsWith("Label")) return false;
      const linkKey = key.replace("Label", "");
      if (notification[linkKey] === true) return false;
    }
  }

  return true;
}
