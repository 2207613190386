import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { MxheroShareConfig } from "@dashboard-v3/api";

interface AuthOptionProps {
  form: MxheroShareConfig;
  updateForm: Dispatch<SetStateAction<MxheroShareConfig>>;
}

export default function AuthenticationOptions({
  form,
  updateForm,
}: AuthOptionProps) {
  const { t } = useTranslation("storage");
  const classes = useStyles();
  console.log(form);
  function onMethodChange(e) {
    const { name, checked } = e.target;

    updateForm(prev => {
      const update = { ...prev };
      const [method] = name.split("_");
      const typeKey = name.includes("EXTERNAL")
        ? "externalAuthenticationMethod"
        : "organizationAuthenticationMethod";

      if (checked) {
        update[typeKey] = [...prev[typeKey], method];
      } else {
        update[typeKey] = prev[typeKey].filter(p => p !== method);
      }

      return update;
    });
  }

  return (
    <>
      <Typography variant="subtitle2" className={classes.title}>
        {t("mxheroShares.auth.title")}
      </Typography>
      <div className={classes.optionContainer}>
        <Typography variant="subtitle2" color="textSecondary">
          {t("mxheroShares.auth.option.external")}
        </Typography>
        {getAuthOptions("EXTERNAL", form).map(option => (
          <FormControlLabel
            name={option.name}
            label={
              <Typography className={classes.optionLabel}>
                {t(option.label)}
              </Typography>
            }
            control={
              <Checkbox
                size="small"
                color="primary"
                checked={option.checked}
                onChange={onMethodChange}
              />
            }
          />
        ))}
      </div>
      <div className={classes.optionContainer}>
        <Typography variant="subtitle2" color="textSecondary">
          {t("mxheroShares.auth.option.organization")}
        </Typography>
        {getAuthOptions("ORGANIZATION", form).map(option => (
          <FormControlLabel
            name={option.name}
            label={
              <Typography className={classes.optionLabel}>
                {t(option.label)}
              </Typography>
            }
            control={
              <Checkbox
                size="small"
                color="primary"
                checked={option.checked}
                onChange={onMethodChange}
              />
            }
          />
        ))}
      </div>
    </>
  );
}

const useStyles = makeStyles({
  title: { marginBottom: 10 },
  optionContainer: { marginBottom: 12 },
  optionLabel: { fontSize: 15 },
});

const getAuthOptions = (
  type: "EXTERNAL" | "ORGANIZATION",
  form: MxheroShareConfig
) => {
  const methods = ["CODE", "GOOGLE", "MICROSOFT"] as const;
  const externalAuth = form.externalAuthenticationMethod;
  const organizationAuth = form.organizationAuthenticationMethod;

  return methods.map(method => {
    return {
      name: `${method}_${type}`,
      label: `mxheroShares.auth.method.${method}`,
      checked:
        type === "EXTERNAL"
          ? externalAuth.includes(method)
          : organizationAuth.includes(method),
    };
  });
};
