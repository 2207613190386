import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import { Trans, useTranslation } from "react-i18next";

interface WarningProps {
  open: boolean;
  onClose: () => void;
}

export default function CollaborateWarningModal({
  open,
  onClose,
}: WarningProps) {
  const { t } = useTranslation("rules");
  const { state } = useRuleContext();
  const { actionType } = state.rule;

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DialogTitle disableTypography style={{ padding: "16px 24px 8px 24px" }}>
        <Typography variant="h6" style={{ marginBottom: 7 }}>
          {t("storageFilepath.collaborate.warning.title")}
        </Typography>
        <Typography display="inline" variant="caption" color="error">
          {t("storageFilepath.collaborate.warning.desc")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <List dense disablePadding>
          <ListItem dense>
            <Typography variant="caption" style={{ fontSize: 13 }}>
              <Trans t={t} i18nKey="storageFilepath.collaborate.warning.target">
                <em></em>
              </Trans>
            </Typography>
          </ListItem>
          <ListItem dense>
            <Typography variant="caption">
              <Trans
                t={t}
                i18nKey="storageFilepath.collaborate.warning.provider"
              >
                <em></em>
              </Trans>
            </Typography>
          </ListItem>
          <ListItem dense>
            <Typography variant="caption">
              <Trans
                t={t}
                i18nKey="storageFilepath.collaborate.warning.sevenDaysAccess"
              >
                <em></em>
              </Trans>
            </Typography>
          </ListItem>
          <ListItem dense>
            <Typography variant="caption">
              <Trans
                t={t}
                i18nKey="storageFilepath.collaborate.warning.recipientsAccess"
              >
                <em></em>
              </Trans>
            </Typography>
          </ListItem>
          <ListItem dense>
            <Typography variant="caption">
              <Trans
                t={t}
                i18nKey="storageFilepath.collaborate.warning.securityDefinition"
              >
                <em></em>
              </Trans>
            </Typography>
          </ListItem>
          <ListItem dense>
            <Typography variant="caption">
              {t("storageFilepath.collaborate.warning.securityPolicy")}
            </Typography>
          </ListItem>
          <ListItem dense>
            <Typography variant="caption">
              <Trans
                t={t}
                i18nKey="storageFilepath.collaborate.warning.smtp"
                values={{ option: t(`targetStep.smtp.${actionType}.enable`) }}
              >
                <em></em>
              </Trans>
              {/* {t("storageFilepath.collaborate.warning.smtp", {
                option: t(`targetStep.smtp.${actionType}.enable`),
              })} */}
            </Typography>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disableElevation
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
