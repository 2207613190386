import { useState } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Button, Menu, MenuItem } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";

type Props = {};

export default function LanguageSelector({ ...rest }: Props) {
  const { t } = useTranslation("common");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = newLng => {
    setAnchorEl(null);
    i18next.changeLanguage(newLng);
  };

  return (
    <>
      <Button
        style={{ marginRight: "20px", color: "grey" }}
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={handleClick}
        startIcon={
          <LanguageIcon style={{ position: "relative", top: "-1px" }} />
        }
        {...rest}
      >
        {t(`language.${i18next.languages?.[0]}`)}
      </Button>
      <Menu
        id="simple-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {i18next.languages?.map(language => (
          <MenuItem
            key={language}
            onClick={() => handleClose(language)}
            value={language}
          >
            {t(`language.${language}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
