import { useTranslation } from "react-i18next";
import produce from "immer";
import {
  AccordionDetails,
  AccordionSummary,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import { getApprovalSteps } from "pages/Rules/CreateOrEdit/context/helpers";
import { SettingFieldsContainer, SettingsContainer } from "../styled";

import { ChangeEventHandler } from "react";

export default function DoneNotificationSettings() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const { doneNotification } = getApprovalSteps(state.rule);

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { name, value } = e.target;

    const update = produce(doneNotification, draft => {
      draft[name] = value;
    });

    dispatch({
      type: "updateStep",
      payload: {
        step: "approvalSteps",
        changes: { doneNotification: update },
      },
    });
  };

  return (
    <SettingsContainer>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
          {t("approvalSteps.doneNotification.sectionTitle")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ paddingBottom: 40 }}>
        <SettingFieldsContainer>
          <TextField
            name="title"
            variant="outlined"
            label={t("approvalSteps.doneNotification.title")}
            value={doneNotification.title}
            onChange={onChange}
          />
          <TextField
            name="approvedBodyText"
            multiline
            minRows={4}
            variant="outlined"
            label={t("approvalSteps.doneNotification.approvalBody")}
            value={doneNotification.approvedBodyText}
            onChange={onChange}
          />
          <TextField
            name="rejectedBodyText"
            multiline
            minRows={4}
            variant="outlined"
            label={t("approvalSteps.doneNotification.rejectBody")}
            value={doneNotification.rejectedBodyText}
            onChange={onChange}
          />
        </SettingFieldsContainer>
      </AccordionDetails>
    </SettingsContainer>
  );
}
