import { Trans, useTranslation } from "react-i18next";
import { FormState } from ".";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import apiRequest from "utils/apiRequestWithErrorCode";
import { Box, CircularProgress } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import ConfirmDelete from "components/ConfirmDelete";

export default function MalwareBanner({
  malware,
  shareId,
}: {
  malware: FormState["malware"];
  shareId: string;
}) {
  const { t } = useTranslation("malwareBanner");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  async function allowMalwareContent() {
    try {
      setLoading(true);
      await apiRequest("DELETE", `/mxhero-shares/${shareId}/malware`);
      enqueueSnackbar(t("allowedDone"), { variant: "success" });
      history.push("/mxhero-shares");
    } catch (error) {
      enqueueSnackbar(t("allowedFailed"), { variant: "error" });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Box mb={4}>
        <Alert severity="error">
          <AlertTitle>{t("warning")}</AlertTitle>
          <Trans i18nKey="title">
            This file contains <strong>malware</strong> and is not accessible to
            the link recipients.
          </Trans>
          <Box mt={2}>
            {t("foundTitle")}
            <ul>
              {malware.threats.map(threat => (
                <li key={threat}>{threat}</li>
              ))}
            </ul>
          </Box>
          <Box mt={4}>
            <Trans i18nKey="explain">
              You can release this share if you are sure it is not malware and
              makes this accessible to the link recipients.{" "}
              <Box display="block" fontWeight="500">
                Use this action at your own risk.
              </Box>
            </Trans>
          </Box>
          <Box mb={2} mt={3}>
            <ConfirmDelete
              variant="contained"
              color="secondary"
              message={t("confirm")}
              disableElevation
              onAccept={allowMalwareContent}
              disabled={loading}
              label={
                loading ? (
                  <>
                    <CircularProgress
                      size={20}
                      style={{ position: "absolute" }}
                    />
                    {t("action")}
                  </>
                ) : (
                  t("action")
                )
              }
            />
          </Box>
        </Alert>
      </Box>
    </>
  );
}
