import { Box, MenuItem, Select, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CreateForm } from ".";

type ValueType = CreateForm["params"]["useAsKey"];

type Params = {
  title: string;
  value: ValueType;
  fileOrFolder: CreateForm["params"]["type"];
  onChange: (newValue: ValueType) => void;
};
export default function SelectUseAs({
  title,
  value,
  fileOrFolder,
  onChange,
}: Params) {
  const { t } = useTranslation("datasets");

  const type = t(`syncForm.${fileOrFolder === "FILE" ? "file" : "folder"}`);

  return (
    <Box display="flex" alignItems="center" mt={2} gridGap="1rem">
      <Box>
        <Typography>{title}</Typography>
      </Box>
      <Box>
        <Select
          variant="outlined"
          value={value}
          onChange={event => {
            onChange(event.target.value as "id" | "name");
          }}
        >
          <MenuItem value="id">{t("syncForm.theID", { type })}</MenuItem>
          <MenuItem value="name">{t("syncForm.theName", { type })}</MenuItem>
        </Select>
      </Box>
    </Box>
  );
}
