import { DatasetSyncRun } from "@dashboard-v3/api";
import {
  Box,
  LinearProgress,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { fetcher } from "utils/apiRequestWithErrorCode";

const MAX_RETRIES = 10;

export default function SyncRuns({ datasetId }: { datasetId: string }) {
  const { t } = useTranslation("datasets");

  const { data: runs, isLoading } = useSWR<DatasetSyncRun[]>(
    `/datasets/${datasetId}/dataset-sync-runs`,
    fetcher,
    {
      refreshInterval: runs => {
        if (!runs) return 0;
        if (runs.some(run => run.status === "PROCESSING")) {
          const every5seconds = 5_000;
          return every5seconds;
        } else {
          const every30seconds = 30_000;
          return every30seconds;
        }
      },
    }
  );

  if (isLoading)
    return (
      <Box m={2} mt={3}>
        <LinearProgress />
      </Box>
    );

  if (runs.length === 0) {
    return (
      <Box py={3} pl={2}>
        <Typography>{t("syncRuns.empty")}</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box pb={2} pt={1} fontSize="1.2rem" fontWeight="500">
        {t("syncRuns.title")}
        <Box component="span" ml={2}>
          <Typography variant="caption" color="textSecondary">
            {t("syncRuns.subtitle")}
          </Typography>
        </Box>
      </Box>
      {runs.map(run => (
        <Box
          key={run.id}
          border="1px solid #e5e5e5"
          padding="10px 20px"
          marginTop="10px"
        >
          <Item syncRun={run} />
        </Box>
      ))}
    </>
  );
}

const Item = ({ syncRun }: { syncRun: DatasetSyncRun }) => {
  const { t } = useTranslation("datasets");

  switch (syncRun.status) {
    case "DONE":
      return (
        <ListItemText
          primary={<Typography>{t("syncRuns.done")}</Typography>}
          secondary={t("syncRuns.endsAt", {
            time: new Date(syncRun.endsAt).toLocaleString(),
            interpolation: { escapeValue: false },
          })}
        />
      );

    case "PROCESSING":
      return (
        <ListItemText
          primary={t("syncRuns.processing")}
          secondary={
            <>
              <Box display="inline">
                {t("syncRuns.startedAt", {
                  time: new Date(syncRun.startedAt).toLocaleString(),
                  interpolation: { escapeValue: false },
                })}
              </Box>
              {Boolean(syncRun.retryContext?.currentRetry) && (
                <Box display="inline" ml={3}>
                  {t("syncRuns.retryN", {
                    times: syncRun.retryContext?.currentRetry,
                  })}
                </Box>
              )}
            </>
          }
        />
      );

    case "WAITING_RETRY_CANCELED":
      return (
        <ListItemText
          primary={t("syncRuns.retryCanceled")}
          secondary={
            <>
              <Box display="inline">
                {t("syncRuns.failedAt", {
                  time: new Date(
                    syncRun.retryContext.lastFailAt
                  ).toLocaleString(),
                  interpolation: { escapeValue: false },
                })}
              </Box>
            </>
          }
        />
      );

    case "WAITING_RETRY":
      return (
        <ListItemText
          primary={
            <>
              <Box display="inline">{t("syncRuns.waitingRetry")}</Box>
              {Boolean(syncRun.errorCode) && (
                <Box display="inline" ml={3}>
                  <Typography
                    component="span"
                    variant="caption"
                    color="textSecondary"
                  >
                    {t(`syncRuns.error_${syncRun.errorCode}`, "")}
                  </Typography>
                </Box>
              )}
            </>
          }
          secondary={
            <>
              <Box display="inline">
                {t("syncRuns.failedAt", {
                  time: new Date(
                    syncRun.retryContext.lastFailAt
                  ).toLocaleString(),
                  interpolation: { escapeValue: false },
                })}
              </Box>
              {Boolean(syncRun.retryContext?.currentRetry) && (
                <Box display="inline" ml={3}>
                  {t("syncRuns.retryXofY", {
                    x: syncRun.retryContext?.currentRetry + 1,
                    y: MAX_RETRIES,
                  })}
                </Box>
              )}
              <Box ml={3} display="inline">
                {t("syncRuns.retryExplain")}
              </Box>
            </>
          }
        />
      );

    case "ERROR":
      return (
        <ListItemText
          primary={t("syncRuns.error")}
          secondary={
            <>
              <Box display="inline">
                {t("syncRuns.endsAt", {
                  time: new Date(syncRun.endsAt).toLocaleString(),
                  interpolation: { escapeValue: false },
                })}
              </Box>

              <Box display="inline" ml={3}>
                {t(`syncRuns.error_${syncRun.errorCode}`, "")}
              </Box>
            </>
          }
        />
      );

    default:
      break;
  }
};
