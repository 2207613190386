import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TracingEvent } from "@dashboard-v3/api";
import formatBytes from "utils/formatBytes";
import { PropertyData, PropertyLabel } from "./Styled";

type DataProps = { data: TracingEvent["data"] };

export default function MailDeliveredData({ data }: DataProps) {
  const { t } = useTranslation("tracing");

  const multiRecipients = data.recipients && data.recipients.length > 0;

  return (
    <>
      <Grid item xs={12}>
        <PropertyLabel>{t("messageId")}</PropertyLabel>
        <PropertyData color="textPrimary">{data.messageId}</PropertyData>
      </Grid>
      <Grid item xs={6} lg={3}>
        <PropertyLabel>{t("from")}</PropertyLabel>
        <PropertyData color="textPrimary">{data.from}</PropertyData>
      </Grid>
      <Grid item xs={6} lg={3}>
        <PropertyLabel>{t("to")}</PropertyLabel>
        <PropertyData color="textPrimary">{data.to}</PropertyData>
      </Grid>
      <Grid item lg={multiRecipients ? 5 : 3}>
        <PropertyLabel>{t("sender")}</PropertyLabel>
        <PropertyData color="textPrimary">{data.sender}</PropertyData>
      </Grid>
      {multiRecipients ? (
        <Grid item lg={12}>
          <PropertyLabel>{t("recipients")}</PropertyLabel>
          <PropertyData color="textPrimary">
            {data.recipients.join(", ")}
          </PropertyData>
        </Grid>
      ) : (
        <Grid item lg={3}>
          <PropertyLabel>{t("recipient")}</PropertyLabel>
          <PropertyData color="textPrimary">{data.recipient}</PropertyData>
        </Grid>
      )}
      <Grid item xs={6} lg={3}>
        <PropertyLabel>{t("initialSize")}</PropertyLabel>
        <PropertyData color="textPrimary">
          {formatBytes(data.bytes)}
        </PropertyData>
      </Grid>
      <Grid item xs={6} lg={3}>
        <PropertyLabel>{t("hasAttachments")}</PropertyLabel>
        <PropertyData color="textPrimary">
          {(data.attachmentsName || []).length
            ? t("common:yes")
            : t("common:no")}
        </PropertyData>
      </Grid>
      <Grid item xs={12} lg={6}>
        <PropertyLabel>{t("isTnef")}</PropertyLabel>
        <PropertyData color="textPrimary">
          {data.isTnef ? t("common:yes") : t("common:no")}
        </PropertyData>
      </Grid>
    </>
  );
}
