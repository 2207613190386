/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Grid, Paper, TextField, Typography } from "@material-ui/core";
import PageLoading from "components/PageLoading";
import { OptionGroup, StyledPaper } from "components/Styled";
import PillButton from "components/Forms/PillButton";
import { Button } from "components/Forms/StyledComponents";
import apiRequest, { RequestMethod } from "utils/apiRequestWithErrorCode";
import Predicate from "./FilterPolicyPredicate";

import {
  FilterPolicy,
  FilterPolicyReqBody,
  FilterPolicyPredicate,
} from "@dashboard-v3/api";

type FormState = {
  name: string;
  description?: string;
  predicates: FilterPolicyPredicate[];
};

type EditState = {
  pathname: string;
  state: { policyId: string; selectedPolicy: FormState };
};

export default function FilterPolicyForm() {
  const { t } = useTranslation("filterPolicies");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id: paramId } = useParams();
  const { state, pathname }: EditState = useLocation();
  const isEdit = pathname.includes("/edit") && Boolean(paramId);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState<FormState>(
    initForm(state?.selectedPolicy)
  );

  useEffect(() => {
    const fetchPolicyById = async (id: string) => {
      setLoading(true);
      try {
        const path = `/filter-policies/${id}`;
        const policy = await apiRequest<FilterPolicy>("GET", path);
        setFormState(policy);
      } catch (e: unknown) {
        enqueueSnackbar(t("form.notification.fetchByIdError"), {
          variant: "error",
        });
        history.push("/filter-policies");
      } finally {
        setLoading(false);
      }
    };

    async function handleEditData() {
      if (state?.policyId === paramId) return;
      await fetchPolicyById(paramId);
    }

    if (isEdit) {
      handleEditData();
    }
  }, []);

  const addPredicate = () => {
    const { predicates } = initForm();
    const [predicate] = predicates;
    setFormState(prev => ({
      ...prev,
      predicates: [...formState.predicates, predicate],
    }));
  };

  const removePredicate = (index: number) => {
    const { predicates } = formState;
    if (predicates.length > 1) {
      setFormState(prev => {
        const predicatesUpdate = predicates.filter((_, i) => i !== index);
        return { ...prev, predicates: predicatesUpdate };
      });
    }
  };

  const onPredicateChange = (
    idx: number,
    update: Partial<FilterPolicyPredicate>
  ) => {
    setFormState(prev => {
      const newState = { ...prev };
      newState.predicates[idx] = { ...newState.predicates[idx], ...update };
      return newState;
    });
  };

  const onInputChange = e => {
    const { value, name } = e.target;
    setFormState(prev => ({ ...prev, [name]: value }));
  };

  const savePolicy = async (body: FilterPolicyReqBody, id?: string) => {
    setLoading(true);

    try {
      const { path, method } = {
        path: id ? `/filter-policies/${id}` : "/filter-policies",
        method: id ? "PUT" : ("POST" as RequestMethod),
      };
      await apiRequest<FilterPolicy>(method, path, body);
      enqueueSnackbar(t("form.notification.savedPolicy"), {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(t("form.notification.saveError"), {
        variant: "error",
      });
    } finally {
      setLoading(false);
      history.push("/filter-policies");
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (state && isEdit) {
      const { policyId } = state;
      savePolicy(formState, policyId);
    } else {
      savePolicy(formState);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PageLoading loading={loading} />
      <Grid container spacing={5}>
        <Grid item lg={8} xs={12}>
          <Paper style={{ padding: "15px 24px 24px 24px" }}>
            <Typography
              gutterBottom
              variant="h6"
              style={{ marginBottom: "20px" }}
            >
              {t(`form.title.${isEdit ? "edit" : "new"}`)}
            </Typography>
            <OptionGroup>
              <TextField
                name="name"
                label={t("form.name.label")}
                variant="outlined"
                value={formState.name}
                onChange={onInputChange}
                fullWidth
                required
              />
            </OptionGroup>
            <OptionGroup>
              <TextField
                name="description"
                label={t("form.description.label")}
                variant="outlined"
                value={formState.description || ""}
                onChange={onInputChange}
                fullWidth
              />
            </OptionGroup>
          </Paper>
          {formState.predicates.map((_, i) => (
            <Predicate
              key={i}
              index={i}
              predicate={formState.predicates[i]}
              showRemoveBtn={formState.predicates.length > 1}
              onRemovePredicate={() => removePredicate(i)}
              onPredicateChange={update => onPredicateChange(i, update)}
            />
          ))}
          <PillButton
            wording={t("form.addConditionBtn")}
            onClick={addPredicate}
            lineSeparator="full"
          />

          <StyledPaper style={{ padding: "25px 24px" }}>
            <Button
              type="submit"
              size="large"
              fullWidth
              wording={t("form.savePolicyBtn")}
            />
          </StyledPaper>
        </Grid>
      </Grid>
    </form>
  );
}

function initForm(savedPolicy?: FormState): FormState {
  if (savedPolicy) return savedPolicy;

  return {
    name: "",
    description: "",
    predicates: [
      {
        match: true,
        firstMatch: false,
        variableIdList: [],
      },
    ],
  };
}
