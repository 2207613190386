import { Grid, Box, Divider, Typography } from "@material-ui/core";
import { PropsWithChildren } from "react";

type Params = {
  timestamp: number;
};

export default function Title({
  timestamp,
  children,
}: PropsWithChildren<Params>) {
  return (
    <Grid item xs={12}>
      <Box mt={3}>
        <Divider />
        <Box mt={2}>
          <Typography color="textSecondary" variant="body2">
            {new Date(timestamp).toLocaleString()}
          </Typography>

          {children}
        </Box>
      </Box>
    </Grid>
  );
}
