import {
  Box,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Link,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  OptionGroup,
  OptionTitle,
  StyledPaper,
  VerticalLine,
} from "components/Styled";
import VariablesSelector from "components/VariablesSelector";
import { ConditionTitle } from "pages/CopyPolicies/Styled";
import { Trans, useTranslation } from "react-i18next";
import useVariables from "utils/useVariables";
import { FilterPolicyPredicate } from "@dashboard-v3/api";

interface PredicateProps {
  index: number;
  predicate: FilterPolicyPredicate;
  showRemoveBtn: boolean;
  onRemovePredicate: () => void;
  onPredicateChange: (update: Partial<FilterPolicyPredicate>) => void;
}

enum MatchOption {
  AllMatch = "ALL_MATCH",
  OneMatches = "ONE_MATCHES",
  NoMatches = "NO_MATCHES",
}

export default function Predicate(props: PredicateProps) {
  const { t } = useTranslation("filterPolicies");
  const { loading, userVariables } = useVariables();
  const { showRemoveBtn, predicate, onRemovePredicate } = props;

  function emptyVariables() {
    if (loading) return false;
    return userVariables?.length === 0;
  }

  function onVariableChange(variableIds: string[]) {
    props.onPredicateChange({ variableIdList: variableIds });
  }

  const onMatchChange = e => {
    const option = e.target.value as MatchOption;
    const state = { ...predicate };

    if (option === MatchOption.AllMatch) {
      state.firstMatch = false;
      state.match = true;
    }

    if (option === MatchOption.NoMatches) {
      state.firstMatch = false;
      state.match = false;
    }

    if (option === MatchOption.OneMatches) {
      state.firstMatch = true;
      state.match = true;
    }
    props.onPredicateChange({ ...state });
  };

  const getMatchValue = () => {
    const { match, firstMatch } = predicate;
    if (match && !firstMatch) return MatchOption.AllMatch;
    if (match && firstMatch) return MatchOption.OneMatches;
    if (!match && !firstMatch) return MatchOption.NoMatches;
    return MatchOption.AllMatch;
  };

  return (
    <>
      <VerticalLine size="sm" />
      <StyledPaper>
        <ConditionTitle color="textSecondary">
          {t("form.conditionTitle", { count: props.index + 1 })}
          {showRemoveBtn && (
            <IconButton
              size="small"
              aria-label="delete"
              onClick={onRemovePredicate}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </ConditionTitle>
        <OptionGroup>
          <OptionTitle style={{ marginTop: "8px", marginBottom: "8px" }}>
            {t("form.variables.title")}
          </OptionTitle>
          <VariablesSelector
            label={t("form.variables.label")}
            value={predicate.variableIdList}
            onChange={onVariableChange}
            required
          />
          <Box display="flex" mt=".5em" mb="1em">
            <RadioGroup
              row
              aria-label="variable-match"
              name="variable-match"
              value={getMatchValue()}
              onChange={onMatchChange}
            >
              <FormControlLabel
                value={MatchOption.AllMatch}
                control={<Radio color="primary" />}
                label={t("form.variableMatch.allMatch")}
              />
              <FormControlLabel
                value={MatchOption.OneMatches}
                control={<Radio color="primary" />}
                label={t("form.variableMatch.oneMatches")}
              />
              <FormControlLabel
                value={MatchOption.NoMatches}
                control={<Radio color="primary" />}
                label={t("form.variableMatch.noMatches")}
              />
            </RadioGroup>
          </Box>
          {emptyVariables() && (
            <FormHelperText margin="dense" error>
              <Trans i18nKey="youCanExcludeCertainAttachments" t={t}>
                Cannot create a condition without at least one{" "}
                <Link href="/variables/new" underline="always">
                  variable
                </Link>
              </Trans>
            </FormHelperText>
          )}
        </OptionGroup>
      </StyledPaper>
    </>
  );
}
