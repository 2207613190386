import { Rule } from "pages/Rules/types";
import { getArchivingAddress, getEmailFlow, getMailbox } from "./helpers";
import { State } from "./types";

export default function initChecks(rule: Rule) {
  const isEdit = Boolean(rule.id);

  let checks: State["checks"] = {
    archivingAddress: {
      isValidEmail: false,
      testedEmail: null,
    },
    emailFlow: {
      isValid: false,
      isUnmanaged: false,
    },
    mailboxImap: {
      isValid: false,
    },
    storageAccess: {
      isValid: false,
    },
    storageFilename: {
      isValid: true,
    },
    canCollaborateFolder: true,
  };

  if (isEdit) {
    if (rule.targetType === "ARCHIVING_ADDRESS") {
      const archivingAddress = getArchivingAddress(rule);
      checks.archivingAddress = {
        isValidEmail: true,
        testedEmail: archivingAddress.emailAddress,
        initialAddress: archivingAddress.emailAddress,
      };
    }

    if (rule.targetType === "EMAIL_FLOW") {
      const emailFlow = getEmailFlow(rule);
      const initialValue = {
        bidirectional: emailFlow.bidirectional,
        fromTo: emailFlow.fromTo,
      };
      checks.emailFlow = {
        isValid: true,
        isUnmanaged: false,
        tested: initialValue,
        initialValue,
      };
    }

    if (rule.targetType === "MAIL_BOX") {
      const mailbox = getMailbox(rule);
      if (mailbox.provider === "IMAP") {
        checks.mailboxImap = {
          isValid: true,
          tested: { ...mailbox.imapServer },
          initialValue: { ...mailbox.imapServer },
        };
      }
    }
    checks.storageAccess = {
      isValid: true,
    };
  }

  return checks;
}
