import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface RetentionProps {
  disabled: boolean;
  value: string;
  onChange: RadioGroupProps["onChange"];
}

enum Retention {
  OneYear = "ONE_YEAR",
  Permanent = "PERMANENT",
}

export default function RetentionOptions(props: RetentionProps) {
  const { t } = useTranslation("storage");
  const { value, onChange, disabled } = props;

  return (
    <FormControl fullWidth disabled={disabled}>
      <Typography variant="subtitle2">
        {t("mxheroShares.retention.title")}
      </Typography>
      <RadioGroup
        name="retention"
        style={{ flexDirection: "row" }}
        value={value}
        onChange={onChange}
      >
        <FormControlLabel
          control={<Radio color="primary" size="small" />}
          label={t(`mxheroShares.retention.${Retention.OneYear}`)}
          value={Retention.OneYear}
        />
        <FormControlLabel
          control={<Radio color="primary" size="small" />}
          label={t(`mxheroShares.retention.${Retention.Permanent}`)}
          value={Retention.Permanent}
        />
      </RadioGroup>
    </FormControl>
  );
}
