/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Box,
  Typography,
} from "@material-ui/core";
import { OptionGroup, OptionTitle } from "components/Styled";
import { Button } from "components/Forms/StyledComponents";
import { StorageProvider } from "@dashboard-v3/api";
import PickerModal, {
  pickersEnabled,
} from "pages/Rules/StorageFilepath/FilepathSelector/PickerModal";
import { PickerParams } from "pages/Rules/StorageFilepath/FilepathSelector/types";

type PathType = "ROOT" | "PICKER";
type Provider = Omit<StorageProvider, "MXHERO_STORAGE">;

export default function FolderPathSelector({
  value,
  onChange: emitChange,
  storageProvider,
}: {
  value?: { parentId: string; parentName?: string };
  onChange: (parentId: string, parentName?: string) => void;
  storageProvider: Provider;
}) {
  console.log("Run");
  const { t } = useTranslation(["components", "rules", "datasets"]);
  const [pathType, setPathType] = useState<PathType>(
    value.parentId && value.parentName ? "PICKER" : "ROOT"
  );
  const [openPicker, setOpenPicker] = useState<boolean>(false);

  const isPickerAvailable = pickersEnabled.includes(storageProvider as string);

  const handlePathTypeChange = event => {
    const value = event.target.value as PathType;
    setPathType(value);
    emitChange("");
  };

  const folderPickerChange: PickerParams["onChange"] = folder => {
    if (folder) {
      emitChange(folder.parentId, folder.parentName);
    }
    setOpenPicker(false);
  };

  return (
    <OptionGroup>
      <OptionGroup>
        <Box mb={1}>
          <OptionTitle>{t("datasets:syncForm.folderPathTitle")}</OptionTitle>
          <Typography color="textSecondary" variant="caption">
            {t("datasets:syncForm.folderPathSubtitle")}
          </Typography>
        </Box>
        <RadioGroup
          defaultValue={"ROOT"}
          row
          value={pathType}
          onChange={handlePathTypeChange}
        >
          {isPickerAvailable && (
            <>
              <FormControlLabel
                value={"ROOT"}
                label={t("storageFilepath.root")}
                control={<Radio color="primary" />}
              />
              <FormControlLabel
                value={"PICKER"}
                label={t("storageFilepath.pickFromStorage")}
                control={<Radio color="primary" />}
                onChange={() => setOpenPicker(true)}
              />
              {pathType === "PICKER" && (
                <Button
                  variant="outlined"
                  size="small"
                  style={{ alignSelf: "center" }}
                  wording={t("storageFilepath.pickFolder")}
                  onClick={() => setOpenPicker(true)}
                />
              )}
            </>
          )}
        </RadioGroup>
        <Box mt={1}>
          {pathType === "ROOT" && isPickerAvailable ? (
            <></>
          ) : (
            <TextField
              label="Folder path"
              fullWidth
              InputProps={{ readOnly: isPickerAvailable }}
              variant="outlined"
              value={value?.parentName || value?.parentId || ""}
              onChange={event => {
                emitChange(event.target.value);
              }}
            />
          )}
        </Box>

        <PickerModal
          provider={storageProvider as StorageProvider}
          onChange={folderPickerChange}
          open={openPicker}
        />
      </OptionGroup>
    </OptionGroup>
  );
}
