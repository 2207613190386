import styled from "styled-components";
import { Accordion, MenuItem } from "@material-ui/core";

export const StyledMenuItem = styled(MenuItem)`
  display: block;
  opacity: 1 !important;
  cursor: ${({ disabled }) => disabled && "default"};
  pointer-events: ${({ disabled }) => disabled && "none"};
  p {
    opacity: ${({ disabled }) => disabled && "0.5"};
  }
  &:hover {
    background: ${({ disabled }) => disabled && "none"};
  }
`;

export const SettingsContainer = styled(Accordion)`
  width: 100%;
  box-shadow: none;

  .MuiAccordionSummary-root {
    padding: 0;
  }

  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  :before {
    opacity: 0;
  }
`;

export const SettingFieldsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  > .MuiFormControl-root.MuiTextField-root {
    max-width: 90%;
  }
`;

export const SettingsSwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .MuiFormControl-root.MuiTextField-root {
    margin-left: 3%;
    max-width: 87%;
  }
`;
