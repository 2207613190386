import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ComponentList from "components/ComponentList";
import DataSetItem from "./Item";
import { DatasetAndSync } from "@dashboard-v3/api";
import { ShowMoreBtn } from "components/List";
import { Skeleton } from "@material-ui/lab";
import apiRequest from "utils/apiRequestWithErrorCode";
import { useSnackbar } from "notistack";
import { byLastModified } from "utils/sort";

const LIST_LIMIT = 10;

export default function DataSetsList() {
  const { t } = useTranslation("datasets");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<"ALL" | "MORE" | "ITEM">();
  const [datasets, setDatasets] = useState<DatasetAndSync[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [loadingItem, setLoadingItem] = useState<string>();
  const [searchOffset, setSearchOffset] = useState(0);

  useEffect(() => {
    async function initialFetch() {
      setLoading("ALL");
      await fetchDatasets();
      setLoading(null);
    }

    initialFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDatasets = async (offset = 0) => {
    try {
      const res = await apiRequest<DatasetAndSync[]>(
        "GET",
        `/datasets?offset=${offset}&limit=${LIST_LIMIT}`
      );
      setHasMore(res.length === LIST_LIMIT);
      setDatasets(prevDatasets => {
        const newDatasets = offset ? [...res, ...prevDatasets] : res;
        return newDatasets.sort(byLastModified);
      });
    } catch (e) {
      enqueueSnackbar(t(`common:errors.fetchError`), {
        variant: "error",
      });
    }
  };

  const loadMore = async () => {
    setLoading("MORE");
    const newOffset = searchOffset + LIST_LIMIT;
    await fetchDatasets(newOffset);
    setSearchOffset(newOffset);
    setLoading(null);
  };

  return (
    <>
      <ComponentList
        loading={loading === "ALL"}
        list={datasets}
        emptyMsg={t("list.empty")}
        renderItems={dataset =>
          loadingItem === dataset.id ? (
            <Skeleton
              key={dataset.id}
              height="5rem"
              animation="wave"
              variant="rect"
            />
          ) : (
            <DataSetItem
              key={dataset.id}
              content={dataset}
              setLoading={setLoading}
              setLoadingItem={setLoadingItem}
              fetchDatasets={fetchDatasets}
            />
          )
        }
      />
      {hasMore && (
        <ShowMoreBtn loading={loading === "MORE"} onClick={loadMore} />
      )}
    </>
  );
}
