/**
 *
 * @template T
 * @param {T} value
 * @returns {T}
 */
function deepClone(value) {
  if (typeof structuredClone !== 'undefined') {
    return structuredClone(value);
  }
  return JSON.parse(JSON.stringify(value));
}

module.exports = { deepClone };
