import {
  CreateDatasetSync,
  StorageAccount,
  StorageProvider,
} from "@dashboard-v3/api";
import { Box, Grid, MenuItem, Typography } from "@material-ui/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import produce, { Draft } from "immer";
import ButtonWithLoading from "components/ButtonWithLoading";
import apiRequest from "utils/apiRequestWithErrorCode";
import { useSnackbar } from "notistack";
import FileOrFolder from "./FileOrFolder";
import ReplaceOrMerge from "./ReplaceOrMerge";
import SelectStorageAccount from "./SelectStorageAccount";
import SelectUseAs from "./SelectUseAs";
import SelectRepeat from "./SelectRepeat";
import { OptionTitle } from "components/Styled";
import FolderPathSelector from "./FolderPathSelector";
import { useHistory } from "react-router-dom";

export type Task = {
  id: string;
  organizationId: string;
  action: "REPLACE" | "MERGE";
  isScheduled: boolean;
  datasetId: string;
  params: CloudStorageLoader;
};

export type CloudStorageLoader = {
  type: "FILE" | "FOLDER";
  parentId: string;
  parentName?: string;
  useAsKey: "id" | "name";
  useAsValue: "id" | "name";
  storageProvider: Omit<StorageProvider, "MXHERO_STORAGE">;
  storageUser: string;
  storageAccountId: string;
};

export type CreateForm = Partial<Omit<CreateDatasetSync, "params">> & {
  datasetName: string;
  params: Partial<CloudStorageLoader>;
};

export type EditForm = Partial<Omit<CreateDatasetSync, "params">> & {
  datasetId: string;
  datasetName: string;
  params: Partial<CloudStorageLoader>;
};

function isEditing(data: CreateForm | EditForm): data is EditForm {
  return "datasetId" in data;
}

export default function SyncConfigForm({
  data,
}: {
  data: CreateForm | EditForm;
}) {
  const [form, setForm] = useState<CreateForm | EditForm>(data);
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const editing = isEditing(data);
  const history = useHistory();
  const { t } = useTranslation(["datasets", "common"]);

  const canSave = Boolean(form.params.storageUser);
  const canDisable = Boolean(isEditing && data.repeatEvery > 0);
  const fileOrFolder = t(
    `syncForm.${form.params.type === "FILE" ? "file" : "folder"}`
  );

  function updateForm(update: (draft: Draft<CreateForm | EditForm>) => void) {
    return setForm(produce(update));
  }

  function setStorageAccount(storageAccount: StorageAccount) {
    updateForm(draft => {
      draft.params.storageProvider = storageAccount.type;
      draft.params.storageUser = storageAccount.user;
      draft.params.storageAccountId = storageAccount.id;
    });
  }

  async function save() {
    setIsSaving(true);
    try {
      if (editing) {
        await withTimeout(
          apiRequest("PUT", `/datasets/${data.datasetId}/dataset-syncs`, form)
        );
        enqueueSnackbar(t("createSuccess", { name: data.datasetName }), {
          variant: "success",
        });
      } else {
        await withTimeout(
          apiRequest("POST", "/dataset-syncs", {
            ...form,
            datasetName: data.datasetName,
          })
        );
        enqueueSnackbar(t("createSuccess", { name: data.datasetName }), {
          variant: "success",
        });
      }

      history.push("/datasets");
    } catch (error) {
      if (isTimeout(error)) {
        enqueueSnackbar(t("syncForm.proccesing"), {
          variant: "success",
        });
        history.push(`/datasets`);
        return;
      }

      console.error("Error saving task:", error);
      enqueueSnackbar(t("common:errors.fetchError"), {
        variant: "error",
      });
    } finally {
      setIsSaving(false);
    }
  }

  async function disableSync() {
    setIsSaving(true);
    try {
      await withTimeout(
        apiRequest("PUT", `/datasets/${data.datasetId}/dataset-syncs`, {
          ...form,
          repeatEvery: 0,
        })
      );

      enqueueSnackbar(t("syncForm.disabled"), {
        variant: "success",
      });
      history.push(`/datasets`);
    } catch (error) {
      console.error("Error saving task:", error);
      enqueueSnackbar(t("common:errors.fetchError"), {
        variant: "error",
      });
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <form>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SelectStorageAccount
            value={form.params.storageAccountId}
            onChange={setStorageAccount}
          />
        </Grid>
        {form.params.storageProvider && (
          <>
            <Grid item xs={12}>
              <FolderPathSelector
                value={{
                  parentId: form.params.parentId,
                  parentName: form.params.parentName,
                }}
                onChange={(parentId: string, parentName?: string) => {
                  updateForm(draft => {
                    draft.params.parentId = parentId;
                    draft.params.parentName = parentName;
                  });
                }}
                storageProvider={form.params.storageProvider}
              />
            </Grid>
            <Grid item xs={12}>
              <ReplaceOrMerge
                value={form.action}
                onChange={value => {
                  updateForm(draft => {
                    draft.action = value;
                  });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FileOrFolder
                value={form.params.type}
                onChange={newValue => {
                  updateForm(draft => {
                    draft.params.type = newValue;
                  });
                }}
              />

              <Box mt={4}>
                <OptionTitle>
                  {t("syncForm.useAsTitle", { type: fileOrFolder })}
                </OptionTitle>

                <Typography variant="caption">
                  {t("syncForm.useAsSubtitle", { type: fileOrFolder })}
                </Typography>
              </Box>

              <SelectUseAs
                title={t("syncForm.asKeyLabel")}
                value={form.params.useAsKey}
                fileOrFolder={form.params.type}
                onChange={newValue => {
                  updateForm(draft => {
                    draft.params.useAsKey = newValue;
                  });
                }}
              />

              <SelectUseAs
                title={t("syncForm.asValueLabel")}
                value={form.params.useAsValue}
                fileOrFolder={form.params.type}
                onChange={newValue => {
                  updateForm(draft => {
                    draft.params.useAsValue = newValue;
                  });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Box mt={2}>
                <OptionTitle>{t("syncForm.repeatTitle")}</OptionTitle>
                <Typography variant="caption">
                  {t("syncForm.repeatSubtitle")}
                </Typography>
              </Box>
              <SelectRepeat
                value={form.repeatEvery}
                onChange={newValue =>
                  updateForm(draft => {
                    draft.repeatEvery = newValue;
                  })
                }
              />
            </Grid>
          </>
        )}
      </Grid>

      <Box mt={4} display="flex" gridGap="10px">
        <ActionBtn onClick={save} disabled={!canSave} isLoading={isSaving}>
          {isEditing ? t("syncForm.update") : t("syncForm.save")}
        </ActionBtn>

        {canDisable && (
          <ActionBtn
            onClick={disableSync}
            disabled={!canSave}
            isLoading={isSaving}
          >
            {t("syncForm.stopRepeat")}
          </ActionBtn>
        )}
      </Box>
    </form>
  );
}

function ActionBtn({ onClick, disabled, isLoading, children }) {
  return (
    <ButtonWithLoading
      onClick={onClick}
      variant="contained"
      color="primary"
      disableElevation
      disabled={disabled}
      loading={isLoading}
    >
      {children}
    </ButtonWithLoading>
  );
}

export const StyledMenuItem = styled(MenuItem)`
  display: block;
  opacity: 1 !important;
  cursor: ${({ disabled }) => disabled && "default"};
  pointer-events: ${({ disabled }) => disabled && "none"};
  p {
    opacity: ${({ disabled }) => disabled && "0.5"};
  }
  &:hover {
    background: ${({ disabled }) => disabled && "none"};
  }
`;

function isTimeout(error: unknown) {
  return error instanceof Error && error.message === "TIMED_OUT";
}

function withTimeout(fn) {
  const timeout = 5000;
  const timeoutPromise = new Promise((_, reject) =>
    setTimeout(() => reject(new Error("TIMED_OUT")), timeout)
  );

  return Promise.race([fn, timeoutPromise]);
}
