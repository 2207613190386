/* eslint-disable react/prop-types */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  Accordion,
  Box,
  Button,
  Chip,
  Grid,
  LinearProgress,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Details, { getEmailData } from "./Details";
import { fetchEventDetails } from "./api";
import {
  StyledAccordionSummary,
  StyledAccordionDetails,
  PropertyData,
} from "./Styled";
import MailDeliveredData from "./MailDeliveredData";

import { TracingEvent, TracingEventDetails } from "@dashboard-v3/api";

interface ItemProps {
  emailEvent: TracingEvent;
}

export default function Item({ emailEvent }: ItemProps) {
  const { createdAt, data, msgUID } = emailEvent;
  const { t } = useTranslation("tracing");
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [itemDetails, setItemDetails] = useState<TracingEventDetails[]>([]);

  const fetchItemDetails = async () => {
    setLoading(true);
    try {
      const result = await fetchEventDetails(msgUID);
      setItemDetails(result);
    } catch (error) {
      enqueueSnackbar(t("error.fetchItemEvents"), {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  function toggle(_, isExpanded) {
    setExpanded(isExpanded);
    if (isExpanded && !isLoading) {
      fetchItemDetails();
    }
  }

  const emailData = getEmailData(itemDetails);
  const multipleRecipients = data?.recipients?.length > 1;

  return (
    <ListItem disableGutters data-testid="tracing__result-list-item">
      <Accordion
        className={classes.accordion}
        onChange={toggle}
        expanded={expanded}
      >
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <ListItemText
            primary={
              <>
                <Box display="flex" gridGap=".8rem" alignItems="center" mb={1}>
                  {data.preprocess && (
                    <Chip
                      size="small"
                      label="Pre-processed"
                      variant="outlined"
                      color="primary"
                    />
                  )}
                  <Typography color="textSecondary" variant="body2">
                    {new Date(createdAt).toLocaleString()}
                  </Typography>
                </Box>
                <Grid container>
                  <Grid item lg={6}>
                    <Typography
                      color="textSecondary"
                      component="span"
                      variant="body2"
                    >
                      {t("from")}:
                    </Typography>
                    <PropertyData className={classes.data}>
                      {data.from}
                    </PropertyData>
                  </Grid>
                  {multipleRecipients ? (
                    <Grid item lg={6}>
                      <Typography
                        color="textSecondary"
                        component="span"
                        variant="body2"
                      >
                        {t("recipients")}:
                      </Typography>
                      <Recipients values={data.recipients} />
                    </Grid>
                  ) : (
                    <Grid item lg={6}>
                      <Typography
                        color="textSecondary"
                        component="span"
                        variant="body2"
                      >
                        {t("recipient")}:
                      </Typography>
                      <PropertyData className={classes.data}>
                        {data.recipient || data.recipients[0]}
                      </PropertyData>
                    </Grid>
                  )}
                </Grid>
              </>
            }
            secondary={
              <>
                <Typography
                  color="textSecondary"
                  component="span"
                  variant="body2"
                >
                  {t("subject")}:
                </Typography>
                <Typography component="span" className={classes.subject}>
                  {data.subject}
                </Typography>
              </>
            }
          />
        </StyledAccordionSummary>
        <StyledAccordionDetails className={classes.accordionDetails}>
          {isLoading && (
            <Box>
              <LinearProgress />
            </Box>
          )}
          {!isLoading && !!itemDetails.length && (
            <>
              <Grid container spacing={2}>
                <MailDeliveredData data={emailData} />
              </Grid>
              <Details details={itemDetails} />
            </>
          )}
        </StyledAccordionDetails>
      </Accordion>
    </ListItem>
  );
}

function Recipients({ values }: { values: string[] }) {
  const { t } = useTranslation("tracing");
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [first, ...others] = values;

  return (
    <PropertyData className={classes.data}>
      <>
        <>{first}</>
        <Button
          size="small"
          onClick={event => {
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
          }}
          color="primary"
        >
          {t("more")}
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClick={event => event.stopPropagation()}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box p={2}>
            {others.map(recipient => (
              <div key={recipient}>{recipient}</div>
            ))}
          </Box>
        </Popover>
      </>
    </PropertyData>
  );
}

const useStyles = makeStyles({
  subject: { marginLeft: "5px", fontWeight: 500 },
  data: { marginLeft: "5px", fontWeight: 500, display: "inline" },
  accordion: { width: "100%" },
  accordionDetails: { display: "block" },
});
