import { useTranslation } from "react-i18next";
import { useHistory, generatePath } from "react-router-dom";
import { useDependencyModal } from "components/DependencyModal";
import Item from "components/List/Item";
import apiRequest from "utils/apiRequestWithErrorCode";

import { FilterPolicy } from "@dashboard-v3/api";
import { ListStateUpdate } from ".";

export interface PolicyItemProps {
  policy: FilterPolicy;
  fetchPolicies: () => Promise<void>;
  updateListState: (update: ListStateUpdate) => void;
}

export default function FilterPolicyItem(props: PolicyItemProps) {
  const { policy, updateListState, fetchPolicies } = props;
  const { t } = useTranslation("filterPolicies");
  const { errorHandler } = useDependencyModal();
  const history = useHistory();
  const { id } = props.policy;

  const onDelete = async (id: string) => {
    updateListState({ loading: "LOADING_ITEM", loadingItem: id });
    try {
      await apiRequest<void>("DELETE", `/filter-policies/${id}`);
      await fetchPolicies();
    } catch (error) {
      errorHandler(error, t("errors.deleteError"));
    } finally {
      updateListState({ loading: "IDLE", loadingItem: null });
    }
  };

  const goToEdit = () => {
    const { id, name, predicates } = policy;
    const selectedPolicy = {
      name,
      description: policy.description || "",
      predicates,
    };

    history.push({
      pathname: generatePath("filter-policies/:id/edit", { id }),
      state: { policyId: id, selectedPolicy },
    });
  };

  return (
    <Item item={props.policy} onEdit={goToEdit} onDelete={() => onDelete(id)} />
  );
}
