import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, Grid, Container, Typography, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import SectionHeader from "components/SectionHeader";
import DescriptionBlock from "components/DescriptionBlock";
import ActionButton from "./ActionButton";
import { ActionIcon } from "./ActionIcon";
import useOrganization from "utils/useOrganization";

import { RuleType } from "pages/Rules/types";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    textAlign: "center",
  },
}));

export default function ActionTypeSelectionPage() {
  const { t } = useTranslation("rules");
  const [ruleType, setRuleType] = useState<RuleType>();
  const classes = useStyles();
  const history = useHistory();

  const { organization } = useOrganization();

  const requestApprovalEnabled =
    organization?.featureFlags?.supervisorApprovalRules;

  const handleConfirmSelection = () => {
    history.push(`/rules/${ruleType.toLowerCase()}/create`);
  };

  return (
    <>
      <SectionHeader title={t("createARule")}>
        <Typography>{t("rulesDescription")}</Typography>
      </SectionHeader>
      <Container maxWidth="md">
        <Paper className={classes.paper}>
          <Typography style={{ marginBottom: "20px" }} variant="h6">
            {t("whatToDo")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <ActionButton
                action="REPLACE_ATTACHMENTS"
                active={ruleType === "REPLACE_ATTACHMENTS"}
                onClick={setRuleType}
                icon={() => <ActionIcon type="REPLACE_ATTACHMENTS" />}
              />
            </Grid>
            <Grid item xs={3}>
              <ActionButton
                action="DRAG_AND_DROP"
                active={ruleType === "DRAG_AND_DROP"}
                onClick={setRuleType}
                icon={() => <ActionIcon type="DRAG_AND_DROP" />}
              />
            </Grid>
            <Grid item xs={3}>
              <ActionButton
                action="COPY_EMAILS"
                active={ruleType === "COPY_EMAILS"}
                onClick={setRuleType}
                icon={FileCopyIcon}
              />
            </Grid>
            <Grid item xs={3}>
              <ActionButton
                action="MOVE_EMAILS"
                active={ruleType === "MOVE_EMAILS"}
                onClick={setRuleType}
                icon={FlipToFrontIcon}
              />
            </Grid>
            <Grid item xs={3}>
              <ActionButton
                action="PROTECT_EMAILS"
                active={ruleType === "PROTECT_EMAILS"}
                onClick={setRuleType}
                icon={() => <ActionIcon type="PROTECT_EMAILS" />}
              />
            </Grid>
            <Grid item xs={3}>
              <ActionButton
                action="BLOCK_ATTACHMENTS"
                active={ruleType === "BLOCK_ATTACHMENTS"}
                onClick={setRuleType}
                icon={LockOutlinedIcon}
              />
            </Grid>
            {requestApprovalEnabled && (
              <Grid item xs={3}>
                <ActionButton
                  action="REQUEST_APPROVAL"
                  active={ruleType === "REQUEST_APPROVAL"}
                  onClick={setRuleType}
                  icon={BeenhereIcon}
                />
              </Grid>
            )}
          </Grid>
          {ruleType && (
            <DescriptionBlock
              title={`${t(`actions.${ruleType}.title`)} ${t("action")}`}
            >
              {t(`actions.${ruleType}.description`)}
            </DescriptionBlock>
          )}
          <Button
            color="primary"
            data-testid="rules__start-rule-btn"
            disableElevation
            disabled={!ruleType}
            fullWidth
            onClick={handleConfirmSelection}
            size="large"
            style={{ marginTop: "30px" }}
            variant="contained"
          >
            {t("startRuleCreation")}
          </Button>
        </Paper>
      </Container>
    </>
  );
}
