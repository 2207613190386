/* eslint-disable no-console */
import {
  getOrganizationId,
  getUser,
  removeUserFromSession,
  updateLastApiRequestTime,
} from "./authentication";
import * as Sentry from "@sentry/react";

type ExtendedErrorInfo = {
  status?: number;
  description?: Record<string, unknown>;
};

export type RequestMethod = "POST" | "GET" | "PUT" | "PATCH" | "DELETE";

export class ErrorWithCode extends Error {
  code: string;
  status?: number;
  description?: unknown;

  constructor(code: string, more?: ExtendedErrorInfo) {
    super(`Error with code ${code}`);
    this.name = "ErrorWithCode";
    this.code = code;
    if (more) {
      this.description = more.description;
      this.status = more.status;
    }
  }
}

export function isErrorWithCode(error: unknown): error is ErrorWithCode {
  return (
    typeof error === "object" &&
    error !== null &&
    "code" in error &&
    typeof (error as Record<string, unknown>).code === "string"
  );
}

function toErrorCode(maybeError: unknown): ErrorWithCode {
  if (isErrorWithCode(maybeError)) return maybeError;
  return new ErrorWithCode("SYSTEM_FAILURE");
}

export function getErrorStatus(error: unknown) {
  const errorObject = toErrorCode(error);
  return errorObject.status;
}

export function getErrorDescription(error: unknown) {
  const errorObject = toErrorCode(error);
  return errorObject.description;
}

export function getErrorCode<ErrorCodes>(error: unknown) {
  return toErrorCode(error).code as
    | ErrorCodes
    | "TIMEOUT"
    | "SYSTEM_FAILURE"
    | "SESSION_EXPIRED"
    | "CONFLICT"
    | "AUTH_CODE_REQUIRED";
}

export default async function request<ResponseBody>(
  method: RequestMethod,
  path: string,
  body?: unknown
): Promise<ResponseBody> {
  const isFormData = body instanceof FormData;
  const requestId = crypto.randomUUID();

  const props = {
    headers: {
      Accept: "application/json",
      "X-Org-id": getOrganizationId(),
      "Request-Id": requestId,
      ...(!isFormData && body && { "Content-Type": "application/json" }),
    },
    credentials: "include",
    method,
  } as RequestInit;

  if (["POST", "PUT", "PATCH", "DELETE"].includes(method)) {
    props.headers[process.env.REACT_APP_CSRF_HEADER_NAME] = getCsrfValue();
  }

  if (body) {
    props.body = !isFormData ? JSON.stringify(body) : body;
  } else if (method === "POST") {
    props.body = JSON.stringify({});
  }

  const url = `${process.env.REACT_APP_API_URL}${path}`;

  let response: Awaited<ReturnType<typeof fetch>>;

  try {
    response = await fetch(url, props);
  } catch (exception) {
    console.error(exception);
    console.debug(`Request to ${method} ${url}`);

    if (!window?.isWindowClosed) {
      // This IF prevent the log of canceled request by close the window (or tab)
      // Source: https://ishwar-rimal.medium.com/typeerror-failed-to-fetch-a-k-a-pain-in-the-ass-fa04dda1514c
      Sentry.captureException(exception, {
        user: getUser(),
        extra: { url, method, body: props.body, requestId },
      });
    }

    throw new ErrorWithCode("SYSTEM_FAILURE");
  }

  if (response.ok) {
    updateLastApiRequestTime();
    if (response.status === 204) return null;
    return response.json();
  }

  if (response.status === 401) {
    removeUserFromSession();

    const errorBody = await response.json();
    const errorCode = errorBody || "SESSION_EXPIRED";

    let loginPath = "/login";

    if (errorCode === "SESSION_EXPIRED") {
      loginPath += "?msg=SESSION_EXPIRED";
    }

    window.location.replace(loginPath);
    throw new ErrorWithCode(errorCode);
  }

  if (response.status === 504) {
    throw new ErrorWithCode("TIMEOUT", { status: 504 });
  }

  updateLastApiRequestTime();

  let error = null;
  try {
    error = await response.json();
  } catch (parseError: unknown) {
    console.error("Error when trying to parse response to json", error);
  }

  if (response.status === 409) {
    throw new ErrorWithCode(error.code || "CONFLICT", {
      description: error?.description,
      status: response.status,
    });
  }

  throw new ErrorWithCode(error?.code || "SYSTEM_FAILURE", {
    description: error?.description,
    status: response.status,
  });
}

export async function getFromAPI<ResponseBody>(
  path: string
): Promise<ResponseBody> {
  return request("GET", path);
}

export function isConflict(error: unknown) {
  if (isErrorWithCode(error)) {
    return error.status === 409;
  }
  return false;
}

export function isNotFound(error: unknown) {
  if (isErrorWithCode(error)) {
    return error.status === 404;
  }
  return false;
}

export function fetcher<T>(path: string) {
  return request<T>("GET", path);
}

function getCsrfValue() {
  const signedValue = getCookie(process.env.REACT_APP_CRSF_COOKIE_NAME);
  if (!signedValue) return "";
  return (signedValue.split(".") || [])[0];
}

function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}
