import {
  ArchivingAddressFilter,
  EmailFlowFilter,
  MailboxFilter,
} from "@dashboard-v3/api";
import { State } from "../types";
import produce from "immer";
import { typePath } from "../helpers";
import validateFilter from "./validateFilters";

export function updateFilterTarget(
  state: State,
  changes: UpdateFilterTarget["payload"]
): State {
  return produce(state, draft => {
    const { targetType, actionType } = state.rule;

    const isDeleteFilters = changes === null;
    const hasFilterPolicy = Boolean(draft.rule.filterPolicyId);

    if (isDeleteFilters && hasFilterPolicy) {
      delete draft.rule.filterPolicyId;
    }

    if (targetType === "EMAIL_FLOW") {
      const actionTypeSection = typePath(draft.type, draft.rule);
      if ("emailFlow" in actionTypeSection) {
        if (changes) {
          actionTypeSection.emailFlow.filter = changes as EmailFlowFilter;
        } else {
          delete actionTypeSection.emailFlow.filter;
        }
      }
    }

    if (actionType === "COPY_EMAILS" && targetType === "ARCHIVING_ADDRESS") {
      draft.rule.copyEmails.archivingAddress.filter =
        changes as ArchivingAddressFilter;
    }

    if (targetType === "MAIL_BOX") {
      const actionTypeSection = typePath(draft.type, draft.rule);
      if ("mailbox" in actionTypeSection) {
        if (changes) {
          actionTypeSection.mailbox.filter = {
            ...actionTypeSection.mailbox.filter,
            ...changes,
          };
        } else {
          delete actionTypeSection.mailbox.filter;
        }
      }
    }
    validateFilter("targetFilter", draft);
  });
}

export type UpdateFilterTarget = {
  type: "updateFilterTarget";
  payload: EmailFlowFilter | ArchivingAddressFilter | MailboxFilter | null;
};
